import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";


const data = [];

function Media(props) {
  const { loading = false } = props;

  return (
    <div className="main">
      {(loading ? Array.from(new Array(4)) : data).map((item, index) => (
        <Card className="loader-card">
          <Skeleton className="loader-media" />
          <Skeleton />
          <Skeleton />
          <Skeleton width="60%" />
        </Card>
      ))}
    </div>
  );
}

Media.propTypes = {
  loading: PropTypes.bool
};

export default function Loader() {
  return (
    <Box overflow="hidden">
      <Media loading />
    </Box>
  );
}
