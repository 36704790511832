import React, { Component } from "react";
import "./style.css";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component {
  render() {
    return (
      <div
        className="contactUs"
      >
        <div class="address-area">
          <ul class="list-style">
            <h5>
              <img
                src={require("../../assets/icons/Subtraction 3.png")}
                width="6%"
              />{" "}
              Address
            </h5>
            <hr/>
            <p>Plot # 11-C Lane 7 Phase 7 DHA Karachi</p>

            <h5>
              <img
                src={require("../../assets/icons/envelope.png")}
                width="6%"
              />{" "}
              Email
            </h5>
            <hr/>
            <p>info@Tiktakbook.com</p>

            <h5>
              <img
                src={require("../../assets/icons/phone-receiver.png")}
                width="6%"
              />{" "}
              Phone
            </h5>
            <hr/>
            <p>+ 92 334 3569118</p>
          </ul>
        </div>

        <div className="map-div">
          <img src={require("../../assets/icons/Map.png")} width="100%" />
        </div>
      </div>
    );
  }
}

export default SimpleMap;
