import React, { withStyles } from "react";
import history from "../../history";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import banner from "../../assets/background/Mask Group 62.png";
import "./search.css";
import { Button } from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Moment from "react-moment";

import { NavLink } from "react-router-dom";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import MainFooter from "../../components/footer/index";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// import { Range } from "react-range";
import Tooltip from "rc-tooltip";
import Slider from "rc-slider";
import swal from "sweetalert";
import Loader from "../../components/Skeleton/loader";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Collapse from "@material-ui/core/Collapse";
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;
const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

export default class SearchScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showcity: false,
      data: [],
      loading: true,
      max: "",
      min: "",
      date: "",
      State: "",
      isOpen: false,
      open: true,
      StateData: [],
      startDate: new Date(),
      City: [],
      city: "",
      filterSearch: [],
      value: [0, 999],
      searchLoader: false,
      showHeading: "",
      isChecked: false,
      ShowError: false,
      showData: true,
      text: ""
    };
  }
  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));

  handleOpen = () => {
    this.setState({ open: true });
  };
  toggle = () => {
    this.setState({ isOpen: true });
  };
  DateChange = date => {
    this.setState({
      startDate: date
    });
  };
  pick = (e, value) => {
    this.setState({ value });
    this.setState({ min: e[0], max: e[1] });
  };
  // searchloader = () => {
  //   setTimeout(() => {
  //     return <Loader />;
  //   }, 1000);
  // };
  loader = () => {
    return (
      <div className="loding">
        <Loader />
      </div>
    );
  };
  ErrorHandle = () => {
    return (
      <div className="Error">
        <h5>Oops... we didn't find anything that matches this search :( </h5>
        <p>
          Try search for something more general, change the filters or check for
          spelling mistakes
        </p>
        <img src={require("../../assets/icons/search-2.jpg")} />
      </div>
    );
  };
  toggleIsChecked2 = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };
  ButtonClick = () => {
    this.toggleIsChecked2();
  };
  Submit = () => {
    let { State, city, min, max } = this.state;
    fetch(
      `http://deaplearning.com/admin/app/api/ads/filter?min_price=${min}&max_price=${max}&state_id=${State}&city_id=${city}`
    )
      .then(jsonResponse => jsonResponse.json())
      .then(response => {
        const Text = response.map(item => item.state_id);
        if (!response.length == 0) {
          this.setState({ filterSearch: response, text: Text });
          if (State) {
            this.setState({
              searchLoader: true,
              showData: false,
              ShowError: false
            });
          } else {
            swal("pick one fillter", "", "error");
          }
        } else {
          this.setState({
            searchLoader: false,
            showData: false,
            ShowError: true
          });
        }
      })

      .catch(error => console.log(error));
  };
  fatchData = () => {
    const { match } = this.props;
    this.setState({ showHeading: match.params.pathParam1 });
    fetch(
      `http://deaplearning.com/admin/app/api/ads/filter?title=${match.params.pathParam1}&city_id=${match.params.pathParam2}`
    )
      .then(jsonResponse => jsonResponse.json())
      .then(response => {
        this.setState({ data: response });
        this.setState({ loading: false });
      });
    fetch(`http://deaplearning.com/admin/app/api/all/states`)
      .then(jsonResponse => jsonResponse.json())
      .then(response1 => {
        this.setState({ StateData: response1 });
      })

      .catch(error => console.log(error));
  };
  onSelect = e => {
    this.setState({ State: e.target.value });
    fetch(`http://deaplearning.com/admin/app/api/tiktak/city/${e.target.value}`)
      .then(jsonResponse => jsonResponse.json())
      .then(response2 => {
        this.setState({ City: response2 });
      })
      .catch(e => console.log(e, "from server"));
  };
  componentDidMount = () => {
    setTimeout(() => {
      this.fatchData();
    }, 1000);
  };
  SearchData = () => {
    let { filterSearch, loading } = this.state;
    return (
      <>
        <div className="main">
          {filterSearch.map(item => {
            const thumbnails = JSON.parse(item.thumbnail);
            let formatTime = moment.utc(item.created_at).local()._d;
            if (item.featured != 0) {
              return (
                <>
                  <Card className="card" key={item.id}>
                    <NavLink
                      style={{ textDecoration: "none", color: "black" }}
                      to={`/postpre/${item.id}`}
                    >
                      <CardActionArea
                        // href={`/postpre/${post.id}`}
                        className="cardarea"
                      >
                        <CardMedia
                          className="media"
                          image={`http://deaplearning.com/admin/app/public/img/ads/${thumbnails[0]}`}
                        >
                          <Button className="cardbutton">Featured</Button>
                        </CardMedia>

                        <div className="moment-div">
                          <Moment fromNow>{formatTime}</Moment>
                        </div>
                        <CardContent
                          style={{
                            color: "#8A75F8",
                            borderLeft: "4px solid #8A75F8"
                          }}
                          className="add-div"
                        >
                          <Typography
                            variant="body2"
                            component="h6"
                            style={{
                              color: "black",
                              textTransform: "capitalize",
                              fontWeight: "500"
                            }}
                            className="book-headeing"
                          >
                            {item.title}
                          </Typography>

                          <Typography
                            gutterBottom
                            variant="h4"
                            component="h2"
                            className="book-price"
                            style={{ fontWeight: 800, height: 18 }}
                          >
                            {" "}
                            Rs : {item.price}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </NavLink>
                  </Card>
                </>
              );
            } else {
              return (
                <Card className="card" key={item.id}>
                  <CardActionArea
                    // href={`/postpre/${item.id}`}
                    onClick={() =>
                      this.props.history.push(`/postpre/${item.id}`)
                    }
                    className="cardarea"
                  >
                    <img
                      className="media"
                      src={`http://deaplearning.com/admin/app/public/img/ads/${thumbnails[0]}`}
                    />
                    <div className="moment-div">
                      <Moment fromNow>{formatTime}</Moment>
                    </div>
                    <CardContent
                      style={{ color: "#8A75F8" }}
                      className="add-div"
                    >
                      <Typography
                        variant="body2"
                        component="p"
                        style={{
                          color: "black",
                          textTransform: "capitalize",
                          fontWeight: "500"
                        }}
                        className="book-headeing"
                      >
                        {item.title}
                      </Typography>

                      <Typography
                        gutterBottom
                        variant="h4"
                        component="h2"
                        className="book-price"
                        style={{ fontWeight: 800, height: 18 }}
                      >
                        {" "}
                        Rs : {item.price}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            }
          })}
        </div>
      </>
    );
  };
  RanderData = () => {
    let { data, loading } = this.state;
    return (
      <>
        {loading ? (
          this.loader()
        ) : (
          <div className="main">
            {data.map(item => {
              const thumbnails = JSON.parse(item.thumbnail);
              let formatTime = moment.utc(item.created_at).local()._d;
              if (item.featured != 0) {
                return (
                  <>
                    <Card className="card" key={item.id}>
                      <NavLink
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/postpre/${item.id}`}
                      >
                        <CardActionArea
                          // href={`/postpre/${post.id}`}
                          className="cardarea"
                        >
                          <CardMedia
                            className="media"
                            image={`http://deaplearning.com/admin/app/public/img/ads/${thumbnails[0]}`}
                          >
                            <Button className="cardbutton">Featured</Button>
                          </CardMedia>

                          <div className="moment-div">
                            <Moment fromNow>{formatTime}</Moment>
                          </div>
                          <CardContent
                            style={{
                              color: "#8A75F8",
                              borderLeft: "4px solid #8A75F8"
                            }}
                            className="add-div"
                          >
                            <Typography
                              variant="body2"
                              component="h6"
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                                fontWeight: "500",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap"
                              }}
                              className="book-headeing"
                            >
                              {item.title}
                            </Typography>

                            <Typography
                              gutterBottom
                              variant="h4"
                              component="h2"
                              className="book-price"
                              style={{ fontWeight: 800, height: 18 }}
                            >
                              {" "}
                              Rs : {item.price}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </NavLink>
                    </Card>
                  </>
                );
              } else {
                return (
                  <Card className="card" key={item.id}>
                    <NavLink
                      style={{ textDecoration: "none", color: "black" }}
                      to={`/postpre/${item.id}`}
                    >
                      <CardActionArea
                        // href={`/postpre/${item.id}`}

                        className="cardarea"
                      >
                        <img
                          className="media"
                          src={`http://deaplearning.com/admin/app/public/img/ads/${thumbnails[0]}`}
                        />
                        <div className="moment-div">
                          <Moment fromNow>{formatTime}</Moment>
                        </div>
                        <CardContent
                          style={{ color: "#8A75F8" }}
                          className="add-div"
                        >
                          <Typography
                            variant="body2"
                            component="p"
                            style={{
                              color: "black",
                              textTransform: "capitalize",
                              fontWeight: "500"
                            }}
                            className="book-headeing"
                          >
                            {item.title}
                          </Typography>

                          <Typography
                            gutterBottom
                            variant="h4"
                            component="h2"
                            className="book-price"
                            style={{ fontWeight: 800, height: 18 }}
                          >
                            {" "}
                            Rs : {item.price}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </NavLink>
                  </Card>
                );
              }
            })}
          </div>
        )}
      </>
    );
  };

  render() {
    let {
      loading,
      StateData,
      State,
      City,
      min,
      max,
      searchLoader,
      showHeading,
      ShowError,
      showData,
      text
    } = this.state;
    return (
      <div maxWidth="lg">
        {/* <Header history={this.props.history} /> */}
        <div className="searchMain">
          <div className="mainFeaturedPost" style={{ height: 200 }}>
            <Grid container>
              <Grid item md={6}>
                <div className="mainFeaturedPostContent">
                  <Typography
                    component="h1"
                    variant="h4"
                    color="inherit"
                    gutterBottom
                    className="Header-h1"
                  >
                    Showing result Of " {showHeading} "
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="search-div">
            <div className="filter-div">
              <Typography
                className="filerHeading"
                component="p"
                variant="p"
                style={{ color: "gray", fontWeight: 500 }}
              >
                Filters
              </Typography>
              <FormControlLabel
                className="filterSwitch"
                control={
                  <Switch
                    checked={this.state.isChecked}
                    onChange={this.ButtonClick}
                  />
                }
                label="Filters"
              />
              <hr />
              <Collapse in={this.state.isChecked}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 15 }}
                  >
                    Select your State
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    labelWidth={120}
                    className="catagory"
                    value={State}
                    onChange={this.onSelect}
                  >
                    {StateData.map(states => {
                      return (
                        <MenuItem value={states.id}>{states.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <br />
                <br />
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 15 }}
                  >
                    Search your City
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    labelWidth={120}
                    className="catagory"
                    value={this.state.city}
                    onChange={e => this.setState({ city: e.target.value })}
                  >
                    {City.map(states => {
                      return (
                        <MenuItem value={states.id}>{states.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                  }}
                >
                  <TextField
                    id="outlined"
                    label="Min-price"
                    margin="normal"
                    variant="outlined"
                    value={min}
                    disabled
                  />

                  <TextField
                    id="outlined"
                    label="Max-price"
                    margin="normal"
                    variant="outlined"
                    value={max}
                    disabled
                  />
                </div>

                <div>
                  <p style={{ color: "gray", fontWeight: 500 }}>
                    Max - Min prices
                  </p>
                  <Range
                    min={0}
                    max={999}
                    defaultValue={this.state.value}
                    allowCross={true}
                    onChange={this.pick}
                  />
                  <span style={{ color: "gray", fontWeight: 500 }}>Min</span>
                  <span
                    style={{ float: "right", color: "gray", fontWeight: 500 }}
                  >
                    Max
                  </span>
                </div>
                <div className="loadmore">
                  <Button
                    type="submit"
                    className="morebtn"
                    onClick={this.Submit}
                  >
                    Search
                  </Button>
                </div>
              </Collapse>
              <div className="dont-collapse-sm">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 15 }}
                  >
                    Select your State
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    labelWidth={120}
                    className="catagory"
                    value={State}
                    onChange={this.onSelect}
                  >
                    {StateData.map(states => {
                      return (
                        <MenuItem value={states.id}>{states.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <br />
                <br />
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 15 }}
                  >
                    Search your City
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    labelWidth={120}
                    className="catagory"
                    value={this.state.city}
                    onChange={e => this.setState({ city: e.target.value })}
                  >
                    {City.map(states => {
                      return (
                        <MenuItem value={states.id}>{states.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                  }}
                >
                  <TextField
                    id="outlined"
                    label="Min-price"
                    margin="normal"
                    variant="outlined"
                    value={min}
                    disabled
                  />

                  <TextField
                    id="outlined"
                    label="Max-price"
                    margin="normal"
                    variant="outlined"
                    value={max}
                    disabled
                  />
                </div>

                <div>
                  <p style={{ color: "gray", fontWeight: 500 }}>
                    Max - Min prices
                  </p>
                  <Range
                    min={0}
                    max={999}
                    defaultValue={this.state.value}
                    allowCross={true}
                    onChange={this.pick}
                  />
                  <span style={{ color: "gray", fontWeight: 500 }}>Min</span>
                  <span
                    style={{ float: "right", color: "gray", fontWeight: 500 }}
                  >
                    Max
                  </span>
                </div>

                <div className="loadmore">
                  <Button
                    type="submit"
                    className="morebtn"
                    onClick={this.Submit}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
            <div>
              {/* <div className="cat-heacding">
                <Typography
                  component="h1"
                  variant="h4"
                  style={{
                    paddingLeft: 80,
                    color: "#484ec8",
                    fontWeight: 500
                  }}
                  className="cat-btn"
                >
                  Showing result Of " {showHeading} "
                </Typography>
              </div> */}

              {searchLoader ? this.SearchData() : null}
              {showData ? this.RanderData() : null}
            </div>
              <div className="Error-div">
                {ShowError ? this.ErrorHandle() : null}
              </div>
          </div>
          <div className="loadmore">
            <Button className="morebtn"> Load More</Button>
          </div>
        </div>
      </div>
    );
  }
}
