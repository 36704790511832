import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Header from "../../components/header/header";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Timer from "react-compound-timer";
import "./style.css";
import Section from "../../components/demoSection/demoSection";
import MainFooter from "../../components/footer/index";
import PostCard from "../../components/cardsection/Topcard";
import Search from "react-search";
import history from "../../history";
import axios from "axios";
import moment from "moment";
import Moment from "react-moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import swal from "sweetalert";
import Loader from "../../components/Skeleton/loader";
import { NavLink } from "react-router-dom";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      city: "",
      search: "",
      open: false,
      data: [],
      Feature: true,
      City: []
    };
  }

  onchangeSearch = () => {
    let { search, city } = this.state;
    if (search && city) {
      this.props.history.push(`/search/${search}/${city}`);
    } else {
      swal("Fill the search bar", "", "error");
    }
  };
  fatchData = () => {
    fetch("http://deaplearning.com/admin/app/api/ads/featured")
      .then(jsonResponse => jsonResponse.json())
      .then(response => {
        this.setState({ data: response });
        this.setState({ Feature: false });
      })
      .catch(error => console.log(error));
    fetch(`http://deaplearning.com/admin/app/api/tiktak/city/4`)
      .then(jsonResponse => jsonResponse.json())
      .then(response2 => {
        this.setState({ City: response2 });
      })
      .catch(e => console.log(e, "from server"));
  };

  componentDidMount = () => {
    setTimeout(() => {
      this.fatchData();
    }, 500);
  };
  loader = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <Loader />
      </div>
    );
  };

  render() {
    let { Feature, data, showsearch, City } = this.state;

    return (
      <React.Fragment>
        {/* <Header history={this.props.history} /> */}
        <div maxWidth="xl">
          <main>
            <div className="mainFeaturedPost">
              <Grid container>
                <Grid item md={6}>
                  <div className="mainFeaturedPostContent">
                    <Typography
                      component="h2"
                      variant="h4"
                      color="inherit"
                      gutterBottom
                      className="Header-h1"
                    >
                      Welcome To
                    </Typography>
                    <Typography
                      component="h2"
                      variant="h2"
                      color="inherit"
                      gutterBottom
                      className="Header-h1"
                    >
                      TiktakBook
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="search-form">
              <div style={{ padding: 18 }} className="search-home">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ fontSize: 15 }}
                  >
                    Search your City
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    labelWidth={130}
                    style={{ height: 75, width: 500 }}
                    className="catagory"
                    value={this.state.city}
                    onChange={e => this.setState({ city: e.target.value })}
                  >
                    {City.map(states => {
                      return (
                        <MenuItem
                          style={{
                            padding: 10,
                            marginLeft: 20
                          }}
                          value={states.id}
                        >
                          {states.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>

              <TextField
                id="outlined-basic"
                label="Search"
                margin="normal"
                variant="outlined"
                required
                autoFocus={true}
                value={this.state.search}
                onChange={e => this.setState({ search: e.target.value })}
              />
              <div style={{ padding: 15 }}>
                <Button
                  type="submit"
                  style={{
                    width: 140,
                    height: 75,
                    background: "linear-gradient(40deg, #484ec8, #e66cdb)",
                    color: "white"
                  }}
                  onClick={this.onchangeSearch.bind(this)}
                  className="search-btn"
                >
                  {" "}
                  Search
                </Button>
              </div>
            </div>
            <hr />
            <div className="cat-heacding">
              <Typography
                component="h1"
                variant="h4"
                style={{
                  paddingLeft: 120,
                  color: "#484ec8",
                  fontWeight: 500
                }}
                className="cat-btn"
              >
                Featured
              </Typography>
            </div>
            {Feature ? (
              this.loader()
            ) : (
              <div className="root">
                {data.map(post => {
                  const thumbnails = JSON.parse(post.thumbnail);
                  let formatTime = moment.utc(post.created_at).local()._d;
                  return (
                    <>
                      <Card className="card" key={post.id}>
                        <NavLink
                          style={{ textDecoration: "none", color: "black" }}
                          to={`/postpre/${post.id}`}
                        >
                          <CardActionArea
                            // href={`/postpre/${post.id}`}
                            className="cardarea"
                            // onClick={() =>
                            //   this.props.history.push(`/postpre/${post.id}`)
                            // }
                          >
                            <CardMedia
                              className="media1"
                              image={`http://deaplearning.com/admin/app/public/img/ads/${thumbnails[0]}`}
                            >
                              <Button className="cardbutton">Featured</Button>

                              {/* <img
                                src={require("../../assets/icons/addtocart2.png")}
                                style={{ marginLeft: 75, width: 25 }}
                              /> */}
                            </CardMedia>

                            <div className="moment-div">
                              <Moment fromNow>{formatTime}</Moment>
                            </div>
                            <CardContent
                              style={{
                                color: "#8A75F8",
                                borderLeft: "4px solid #8A75F8"
                              }}
                              className="add-div"
                            >
                              <Typography
                                variant="body2"
                                component="h6"
                                style={{
                                  fontSize: 16,
                                  color: "black",
                                  textTransform: "uppercase",
                                  fontWeight: "500"
                                }}
                                className="book-headeing"
                              >
                                {post.title}
                              </Typography>

                              <Typography
                                gutterBottom
                                variant="h4"
                                component="h2"
                                className="book-price"
                                style={{ fontWeight: 800, height: 18 }}
                              >
                                {" "}
                                Rs : {post.price}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </NavLink>
                      </Card>
                    </>
                  );
                })}
              </div>
            )}
            <hr />
            <div className="cat-heacding">
              <Typography
                component="h1"
                variant="h4"
                style={{
                  paddingLeft: 120,
                  color: "#484ec8",
                  fontWeight: 500
                }}
                className="cat-btn"
              >
                Fresh recommendations
              </Typography>
            </div>
            <PostCard/>
            <br />
            <br /> <br />
            <br /> <br />
            <br /> <br />
            <Section />
            <br />
            <br />
          </main>
          {/* <MainFooter history={this.props.history} /> */}
        </div>
      </React.Fragment>
    );
  }
}
