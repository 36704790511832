import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../components/header/header";
import history from "../../history";
import Footer from "../../components/footer/index";
import "./pri.css";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SimpleMap from "../../components/Map/Map";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "white",
    padding: 10,
    paddingTop:0,
    position: "relative"
  }
}));

export default function Contact(props) {
  const classes = useStyles();

  return (
    <div className="fluid">
      {/* <Header history={props.history} /> */}
      <div className="mainFeaturedPost">
        <Grid container>
          <Grid item md={6}>
            <div className="mainFeaturedPostContent">
              <Typography
                component="h2"
                variant="h2"
                color="inherit"
                gutterBottom
                className="Header-h1"
              >
                Contact us
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.root}>
        <SimpleMap />
      </div>
      {/* <Footer history={props.history} /> */}
    </div>
  );
}
