import React from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import "./post.css";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import Header from "../../components/header/header";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Topcard from "../../components/cardsection/Topcard";
import MainFooter from "../../components/footer/index";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Slide } from "react-slideshow-image";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Posts from "../../components/cardsection/Topcard";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import banner from "../../assets/background/Mask Group 62.png";
import PostCard from "../../components/cardsection/Topcard";
import { display } from "@material-ui/system";
import axios from "axios";
import moment from "moment";
import Moment from "react-moment";
import { createBrowserHistory } from "history";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import PostLoader from "./priviewLoading";

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true
  // indicators: true,
  // arrows: true
};
export default class PostPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      checked: true,
      Previewdata: [],
      previewLoding: true,
      Data: [],
      userInfo: []
    };
  }

  renderPriviewLoading = () => {
    return <PostLoader />;
  };

  fatchData = () => {
    const { match } = this.props;
    axios
      .get(
        `http://deaplearning.com/admin/app/api/get/ads/${match.params.itemid}`
      )
      .then(res => {
        const items = res.data;
        // const user = res.data[1]
        this.setState({ Previewdata: items });
        this.setState({ previewLoding: false });
      });
  };
  componentDidMount = () => {
    setTimeout(() => {
      this.fatchData();
    }, 1000);
  };
  OpenModel = () => {
    this.setState({
      open: true
    });
  };

  CloseModel = () => {
    this.setState({ open: false });
  };
  onChange = () => {
    this.setState({ checked: true });
  };

  render(props) {
    let { Previewdata, previewLoding } = this.state;
    return (
      <>
        <div maxWidth="lg">
          <div>
            {previewLoding ? (
              this.renderPriviewLoading()
            ) : (
              <div>
                {Previewdata.map(item => {
                  const thumbnailEncoded = JSON.parse(item.thumbnail);
                  let formatTime = moment.utc(item.created_at).local()._d;

                  return (
                    <div className="post-div">
                      <div className="post-header">
                        <div className="avatar">
                          <img
                            src={`http://deaplearning.com/admin/app/public/img/tiktak/users/${item.user_profile}`}
                          />
                          <h3> {item.user_name}</h3>
                        </div>

                        <div className="setting-area">
                          <a style={{ display: "none" }}>
                            <img
                              src={require("../../assets/icons/setting.png")}
                              width="70%"
                            />
                          </a>
                          <a>
                            <img
                              src={require("../../assets/icons/addtocart.png")}
                              width="70%"
                            />
                          </a>
                          <a>
                            <img
                              src={require("../../assets/icons/reload.png")}
                              width="70%"
                            />
                          </a>
                          <h3>
                            <Moment fromNow>{formatTime}</Moment>
                          </h3>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="img-div">
                            <div className="slide-container">
                              <div className="each-slide">
                                <img
                                  src={`http://deaplearning.com/admin/app/public/img/ads/${thumbnailEncoded[0]}`}
                                />
                              </div>
                              {/* <Slide {...properties} id="priv-slider">
                              <div className="each-slide">
                                <img src={`http://deaplearning.com/admin/app/public/img/ads/${thumbnailEncoded[0]}`}/>
                              </div>
                              <div className="each-slide">
                              <img src={`http://deaplearning.com/admin/app/public/img/ads/${thumbnailEncoded[1]}`}/>
                              </div>
                              <div className="each-slide">
                              <img src={`http://deaplearning.com/admin/app/public/img/ads/${thumbnailEncoded[2]}`}/>
                              </div>
                              <div className="each-slide">
                              <img src={`http://deaplearning.com/admin/app/public/img/ads/${thumbnailEncoded[3]}`}/>
                              </div>
                              <div className="each-slide">
                              <img src={`http://deaplearning.com/admin/app/public/img/ads/${thumbnailEncoded[4]}`}/>
                              </div>
                            </Slide> */}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-xl-5">
                          <br /> <br />
                          <div className="content">
                            <h4>Contact Detail</h4>
                            <div className="contact">
                              <a href={`tel:${item.phone}`} class="Blondie">
                                <p>{item.phone}</p>
                              </a>
                              <img
                                src={require("../../assets/icons/telephone.png")}
                              />

                              <img
                                src={require("../../assets/icons/sms.png")}
                              />
                              {/* <img src={require("../../assets/icons/call.png")} /> */}
                            </div>
                            <hr style={{ width: "100%" }} />
                            <h4>Book Title</h4>
                            <h5>{item.book_title}</h5>
                            <br className="br" />
                            <hr style={{ width: "100%" }} />
                            <br className="br" />
                            <h4>Catagory</h4>
                            <h5>{item.cat_id}</h5>
                            <br className="br" />
                            <hr style={{ width: "100%" }} />
                            <br className="br" />
                            <h4>Subject </h4>
                            <h5>{item.subject_id}</h5>
                            <br className="br" />
                            <hr style={{ width: "100%" }} />
                            <br className="br" />
                            <h4>Price</h4>
                            <h5 style={{ color: "red" }}> Rs :{item.price}</h5>
                            <br className="br" />
                            <hr style={{ width: "100%" }} />
                            <h4>Exchange</h4>
                            <br className="br" />
                            <h5>{item.exchange}</h5>
                            <hr style={{ width: "100%" }} />
                            <h4>Discription </h4>
                            <br className="br" />
                            <h5>{item.des}</h5>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {/* <PostCard history={this.props.history}/> */}
          </div>
          {/* <MainFooter history={this.props.history} /> */}
        </div>
      </>
    );
  }
}
