import React, { useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Header from "../../components/header/header";
import { green } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Button from "@material-ui/core/Button";
import OTPInput, { ResendOTP } from "otp-input-react";

import "./login.css";
import firebase from "../../config/firbase";
import { NavLink } from "react-router-dom";

export default function OtpCode(props) {
  const [OTP, setOTP] = useState("");
  const renderButton = buttonProps => {
    return (
      <a {...buttonProps} style={{ margin: "0 auto" }}>
        <img
          src={require("../../assets/icons/loader.png")}
          width="15%"
          style={{ paddingRight: 10 }}
        />
        {buttonProps.remainingTime !== 0
          ? `Resend ${buttonProps.remainingTime} sec`
          : "Please wait few min"}
      </a>
    );
  };
  const renderTime = () => React.Fragment;
  return (
    <div class="opt">
      <div className="otpInput">
        <OTPInput
          value={OTP}
          onChange={setOTP}
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={false}
          className="input-otp"
          inputStyles={{
            width: 60,
            height: 70,
            borderRadius: 5,
            fontSize: 30,
            color: "gray",
            background: "rgb(223, 223, 223)",
            border: "1px rgb(223, 223, 223)",
            margin:"0 auto"
          }}
          // secure
        />
      </div>
      <br />
      <br />
      <ResendOTP renderButton={renderButton} renderTime={renderTime} />
      <br />
      <br />
      <br />
      <NavLink to="/" class="btn btn-outline-white btn-rounded" id="btn-next ">
      Next
      </NavLink>
    </div>
  );
}
