import React, { useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Header from "../../components/header/header";
import { green } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Button from "@material-ui/core/Button";
import OTPInput, { ResendOTP } from "otp-input-react";
import { GoogleLogout } from "react-google-login";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import "./login.css";
import firebase from "../../config/firbase";
import Otpcode from "./Otpcode";
import GoogleLogin from "react-google-login";
import { NavLink } from "react-router-dom";

export default class LoginTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      valid: "",
      isSignedIn: false,
      phone: "",
      change: false,
      otpCode: "",
      moveInfo: "",
      data: {}
    };
  }
  componentDidMount() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible"
        // other options
      }
    );
  }
  OtpCodeInsert = () => {
    let { data } = this.state;
    const code = this.state.otpCode;
    const credential = firebase.auth.PhoneAuthProvider.credential(
      data.verificationId,
      code
    );
    firebase
      .auth()
      .signInWithCredential(credential)
      .then(function(result) {
        // User signed in successfully.
        const user = result.user;
        console.log(user, "hello");
        this.setState({ moveInfo: true });
        // ...
      })
      .catch(function(error) {
        // User couldn't sign in (bad verification code?)
        // ...
      });
  };

  onPhone = () => {
    const phoneNumber = this.state.phone;
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(r => {
        this.setState({ data: r });
        // success
        this.setState({ change: true });

      })
      .catch(error => {
        // error
      });
  };

  randerInfo = () => {
    return (
      <form class="form">
        <div class="p-4">
          <h2
            style={{
              paddingBottom: 60,
              color: "gray",
              fontFamily: "Comic Sans MS",
              fontWeight: "600"
            }}
          >
            {" "}
            Profile Details{" "}
          </h2>
          <TextField
            id="filled-basic"
            className="textfield"
            label="FullName"
            margin="normal"
            // variant="filled"
            required={true}
          />
          <TextField
            id="filled-basic"
            className="textfield"
            label="Email"
            margin="normal"
            // variant="filled"
            required={true}
          />
          <br />
          <br />
          <Button
            class="btn btn-outline-white btn-rounded "
            type="next"
            id="btn-next"
            // onClick={this.onPhoneChange}
          >
            Next
          </Button>
        </div>
      </form>
    );
  };
  randerphoneOtp = () => {
    console.log(this.state.data, "varification code ");
    const renderButton = buttonProps => {
      return (
        <a {...buttonProps} style={{ margin: "0 auto" }}>
          <img
            src={require("../../assets/icons/loader.png")}
            width="15%"
            style={{ paddingRight: 10 }}
          />
          {buttonProps.remainingTime !== 0
            ? `Resend ${buttonProps.remainingTime} sec`
            : "Please wait few min"}
        </a>
      );
    };
    const renderTime = () => React.Fragment;
    return (
      <div class="opt">
        <h2
          style={{
            paddingBottom: 60,
            color: "gray",
            fontFamily: "Comic Sans MS",
            fontWeight: "600"
          }}
        >
          {" "}
          OTP Code ....!
        </h2>
        <div className="otpInput">
          <OTPInput
            value={this.state.otpCode}
            onChange={otpCode => this.setState({ otpCode })}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
            id="input-otp"
            inputStyles={{
              width: 60,
              height: 70,
              borderRadius: 5,
              fontSize: 25,
              color: "gray",
              background: "rgb(223, 223, 223)",
              border: "1px rgb(223, 223, 223)",
              margin: "0 auto"
            }}
            inputClassName="input-oooo"
            // secure
          />
        </div>
        <br />
        <br />
        <ResendOTP renderButton={renderButton} renderTime={renderTime} />
        <br />
        <Button
          class="btn btn-outline-white btn-rounded"
          id="btn-next "
          onClick={this.OtpCodeInsert}
        >
          Next
        </Button>
      </div>
    );
  };
  renderPhoneNumberInput = () => {
    const responseGoogle = response => {
      console.log(response, "login");
    };
    return (
      <form class="form">
        <div class="p-4">
          <h2
            style={{
              paddingBottom: 60,
              color: "gray",
              fontFamily: "Comic Sans MS",
              fontWeight: "600"
            }}
          >
            {" "}
            Login with Phone
          </h2>

          <PhoneInput
            value={this.state.phone}
            onChange={phone => this.setState({ phone })}
            required
            id="input"
            placeholder="Enter phone number"
          />
          <br />
          <Button
            class="btn btn-outline-white btn-rounded"
            id="recaptcha-container"
            onClick={this.onPhone}
          >
            Next
          </Button>

          <br />
          <br />
        </div>
        <GoogleLogin
          clientId="253516381807-tr5ojpcbohn6hbhcahf6rp4d40r5t37d.apps.googleusercontent.com"
          buttonText="Login with Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
        />
      </form>
    );
  };
  render(props) {
    let { change, moveInfo } = this.state;

    // if (!user) {
    //   this.props.history.push('Login');
    // } else if (user.name) {
    //   this.props.history.push('Homescreen');
    // } else {
    //   this.props.history.push('AddDetail');
    // }
    return (
      <div>
        {change ? this.randerphoneOtp() : this.renderPhoneNumberInput()}
        {moveInfo ? this.randerInfo() : null}
        
      </div>
    );
  }
}
