import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "../../components/footer/index";
import "./pri.css"
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "white",
    padding: 50,
    paddingTop:0,
    width: "100%",
    textAlign:"justify"
  }
  
}));

export default function Privacy(props) {
  const classes = useStyles();


  return (
    <div className="fluid">
      {/* <Header history={props.history} /> */}
      <div className="mainFeaturedPost">
        <Grid container>
          <Grid item md={6}>
            <div className="mainFeaturedPostContent">
              <Typography
                component="h2"
                variant="h2"
                color="inherit"
                gutterBottom
                className="Header-h1"
              >
               Privacy Policy
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.root}>
        <h2 style={{fontWeight:"400"}}>WHAT IS TIKTAKBOOK</h2>{" "}
        <p>
          TikTakBook is perhaps one of the world’s only mobile application that
          is focus on exchange, buy & sell and donation of Books with one of the
          main aim to conserve forests and trees. At TikTakBook we please
          ticking and tacking to exchange the used book, saving time, natural
          resources and of course money.
        </p>{" "}
        <p>
          At TikTakBook, we believe that more hands a book exchanges, the better
          the bond of humans with nature. Imagine a whole cycle of kids moving
          to next class after clearing the ongoing class. Kids of Class 1 got
          Class 2 and Kids of Class 2 Togo Class 3 and the cycle goes on.
          Parents of each kid go through the cycle of buying completely new set
          of books and end up spending lots of time and money. We urge these
          Parents and we hope that they use TikTakBook for such purpose and are
          able to exchange the book sets, Kids of Class 2 can Sell/Donate books
          to Class 1 and likewise.
        </p>{" "}
        <h2 style={{fontWeight:"400"}}>ACCEPTANCE</h2>
        <p>
          TikTakBook provide a collection of online resources which include
          classified advertisements and forums (collectively, the "Service") on
          the tiktakbook.com and related sites and the mobile application of the
          platform (collectively, the "Website").
        </p>{" "}
        <p>
          The Website and the Service is provide to you subject to these
          TikTakBook Terms of Use (these "Terms"). For the purpose of the Terms
          and wherever the context so requires, the terms 'you' and “your” shall
          mean any person who uses the Website or the Service in any manner
          whatsoever including persons browsing the Website and its content,
          posting comments or any content or responding to any advertisements or
          content on the Website. By accessing, browsing or using the Website or
          Service, you agree to comply with these Terms. Additionally, when
          using a portion of the Service, you agree to conform to any applicable
          posted guidelines for such Service, which may change or be update from
          time to time at TikTakBook sole discretion. You understand and agree
          that you are solely responsible for reviewing these Terms from time to
          time. Should you object to any term or condition of these Terms, any
          guideline, or any subsequent changes thereto or become unhappy with
          TikTakBook or the Service in any way, your only choice is to
          immediately, discontinue use of TikTakBook. TikTakBook may update
          these Terms at any time at its sole discretion. TikTakBook may send
          you notices of changes to the Website or the Terms pursuant to Section
          xxi (I) herein.
        </p>{" "}
        <p>
          TikTakBook may provide a translation of the English version of the
          Terms into other languages. You understand and agree that any
          translation of the Terms into other languages is for your convenience
          only and that the English version governs the terms of your
          relationship with TikTakBook. Furthermore, if there are any
          inconsistencies between the English version of the Terms and any
          translation, the English version of the Terms shall govern.
        </p>{" "}
        <p>
          The app does use third party services that may collect information
          used to identify you.
        </p>{" "}
       
          <h2 style={{fontWeight:"400"}}>DESCRIPTION OF SERVICE AND CONTENT POLICY</h2>
          <p>
            TikTakBook is the next generation of free online classifieds. We act
            as an online marketplace platform to allow our users who comply with
            these Terms to offer, sell, and buy products and services listed on
            the Website. Although you may be able to conduct payment and other
            transactions through the Website, using third-party vendors such as
            PayPal. TikTakBook is not in any way involved in such transactions.
            As a result, and as discussed in more detail in these Terms, you
            hereby acknowledge and agree that TikTakBook is not a party to such
            transactions, has no control over any element of such transactions,
            and shall have no liability to any party in connection with such
            transactions. You use the Service and the Website at your own risk.
          </p>{" "}
          <p>
            You understand that TikTakBook does not control, and is not
            responsible for ads, directory information, business
            listings/information, messages between users. Including without
            limitation e-mails sent from outside TikTakBook domain or other
            means of electronic communication. Whether through the Website or
            another Third Party Website (defined below) or offerings, comments,
            user postings, files, images, photos, video, sounds, business
            listings/information and directory information or any other material
            made available through the Website and the Service ("Content").
          </p>
      
        <p>
          I want to inform you that whenever you use my Service, in a case of an
          error in the app I collect data and information (through third party
          products) on your phone called Log Data. This Log Data may include
          information such as your device Internet Protocol (“IP”) address,
          device name, operating system version, the configuration of the app
          when utilizing my Service, the time and date of your use of the
          Service, and other statistics.
        </p>{" "}
        <p>
          That by using the Website and the Service, you may be expose to
          Content that is offensive, indecent, inaccurate, misleading, or
          otherwise objectionable. You acknowledge and agree that you are
          responsible for and must evaluate, and bear all risks associated with,
          the use of any content that. You may not rely on said content, and
          that under no circumstances will TikTakBook be liable in any way for
          the content. For any loss or damage of any kind incurred because of
          the browsing, using or reading any content listed, e-mailed or
          otherwise made available via the Service. You acknowledge and agree
          that TikTakBook permits such goods and services to be display and
          offered on the Website that confirm with the terms stated herein.
          However, TikTakBook does not pre-screen or approve any Content, but
          that TikTakBook has the right, in its sole and absolute discretion, to
          refuse, delete or move any Content that is or may be available through
          the Service, for violating these Terms and such violation being bring
          to TikTakBook’s knowledge or for any other reason or no reason at all.
          Furthermore, the Website and Content available through the Website may
          contain links to other third party websites ("Third Party Websites"),
          which are completely unrelated to TikTakBook.
        </p>{" "}
        <p>
          If you link to Third Party Websites, you may be subject to those Third
          Party Websites’ terms, conditions, and other policies. TikTakBook
          makes no representation or guarantee as to the accuracy or
          authenticity of the information contained in any such Third Party
          Website, and your linking to any other websites is completely at your
          own risk and TikTakBook disclaims all liability thereto.
        </p>{" "}
        <p>
          You acknowledge and agree that you are solely responsible for your own
          Content posted on, transmitted through, or linked from the Service and
          the consequences of posting, transmitting, linking or publishing it.
          More specifically, you are solely responsible for all Content that you
          upload, email or otherwise make available via the Service. In
          connection with such Content posted on, transmitted through, or linked
          from the Service by you. You affirm, acknowledge, represent, warrant
          and covenant that.
        </p>{" "}
        <p>
          You have developed the contents such as description and/or photographs
          in accordance with the instructions and guidance for placing an ad
          provided by TikTakBook on the website and that any copyrights therein
          belongs to TikTakBook and, to any extent, should you be deemed to own
          any copyright you hereby assign any such copyright to TikTakBook
        </p>{" "}
        <p>
          You acknowledge that the information and/or photographs that you post
          on the Website is stored and compile by TikTakBook in a proprietary
          database and that TikTakBook modifies the uploaded information and/or
          photographs by applying a watermark of TikTakBook logo and in such
          derivate works, the copyright belongs to TikTakBook.
        </p>{" "}
        <p>
          You shall continue to, for such time the Content is available on the
          Website, have the necessary licenses, authorizations, consents, and
          permissions to use such Content on the Service and Website (including
          without limitation all patent, trademark, trade secret, copyright or
          other proprietary rights in and to any and all such Content). Wherever
          required, in such cases where you have the right, hereby authorize and
          license TikTakBook to exclusive use such Content to enable inclusion
          and use of the Content in the manner contemplated by the Service, the
          Website and these
        </p>{" "}
        <p>
          You have the written consent, release, and/or permission of each
          identifiable individual person or business in the Content to use the
          name or likeness of each such identifiable individual person or
          business to enable inclusion and use of the Content in the manner
          contemplated by the Service, the Website and these Terms. For clarity,
          by submitting any content on the Website, as previously mentioned.
          Wherever you retain any ownership rights in the Content, you hereby
          grant to TikTakBook an irrevocable, non-cancellable, perpetual,
          worldwide, exclusive, royalty-free, sub-licensable, transferable
          license to use, reproduce, distribute, and prepare derivative works
          of, display. Perform the Content in connection with the Website and
          TikTakBook's (and its successors') business, including without
          limitation for the purpose of promoting and redistributing part or the
          entire Website
        </p>{" "}
        <p>
          Content therein (and derivative works thereof) in any media formats
          and through any media channels now or hereafter known. Furthermore, by
          you posting content to any public area of the Service, to the extent
          you retain any ownership of any rights. You agree to and do hereby
          grant to TikTakBook all exclusive rights necessary to prohibit or
          allow any subsequent aggregation, display, copying, duplication,
          reproduction, or exploitation of the Content on the Service or Website
          by any party for any purpose including the right to initiate legal
          action in accordance with the copyright and other laws of Pakistan.
          You also hereby grant each user of the Website a non-exclusive license
          to access you are Content through the Website. The foregoing license
          to each user granted by you terminates once you or TikTakBook remove
          or delete such Content from the Website.
        </p>{" "}
        <p>
          TikTakBook does not endorse any Content or any opinion, statement,
          recommendation, or advice expressed therein, and TikTakBook expressly
          disclaims all liability in connection with user Content. TikTakBook
          does not permit copyright infringing activities and infringement of
          intellectual property rights on the Website, and TikTakBook may at its
          sole discretion, remove any infringing Content if properly notified in
          accordance with applicable law that such Content infringes on
          another's intellectual property rights. TikTakBook reserves the right
          to remove any Content without prior notice. TikTakBook may also
          terminate a user's access to the Website, if they are determined to be
          a repeat infringer or found to be indulging in any act contrary to
          these Terms. A repeat infringer is a user who has been notified of
          infringing activity more than twice and/or has had a user submission
          removed from the Website more than twice. Further, at its sole
          discretion, TikTakBook reserves the right to decide whether any
          Content is, appropriate and complies with these Terms.
        </p>{" "}
        <p>
          TikTakBook may provide a video service to you if you upload
          photographs for your ad. By uploading your photographs, not covered by
          2(3) (I) or (ii) above, you irrevocably license and consent to the
          exclusive use of your photographs for this service and represent and
          warrant that you have all right, title and interest necessary to
          upload and use the photographs on this service. Under this service,
          using a third party application or service, TikTakBook may, at its
          discretion, create a video of your photographs, upload the video via
          www.youtube.com or another third party service provider, as determined
          by TikTakBook at its discretion, and embed the video into your ad on
          TikTakBook.
        </p>{" "}
        <h2 style={{fontWeight:"400"}}>FEATURED ADS</h2>
        <p>
          TikTakBook may offer a service known as "Featured Ads" where users may
          pay a non-refundable fee to have their ads posted in selected
          locations on the Website, thus potentially increasing an ads'
          visibility. In order to purchase a Featured Ad, you may be required to
          transmit certain information through a third party service provider,
          which may be govern by its own terms of use and other policies.
          TikTakBook makes no representation or guarantee as to the safety or
          security of the information transmitted to any Third Party service
          provider, and your linking to any Third Party service is completely at
          your own risk, and TikTakBook disclaims all liability related thereto.
        </p>{" "}
        <p>
          Featured Ads are subject to the Terms listed herein, as well as
          additional terms of service.
        </p>{" "}
        <h2 style={{fontWeight:"400"}}>CONDUCT</h2>
        <p>
          You agree not to post, email, host, display, upload, modify, publish,
          transmit, update or share any information on the Site, or otherwise
          make available Content:
        </p>{" "}
        <p>that violates any law or regulation;</p>{" "}
        <p>
          that is copyrighted or patented, protected by trade secret or
          trademark, or otherwise subject to third party other intellectual
          property or proprietary rights, including privacy and publicity
          rights, unless you are the owner of such rights or have permission or
          a license from their rightful owner to post the material and to grant
          TikTakBook all of the license rights granted herein;
        </p>{" "}
        <p>
          that infringes any of the foregoing intellectual property rights of
          any party, or is Content that you do not have a right to make
          available under any law, regulation, contractual or fiduciary
          relationship(s);
        </p>{" "}
        <p>
          that harasses, degrades, intimidates or is hateful towards any
          individual or group of individuals on the basis of religion, gender,
          sexual orientation, race, ethnicity, age, or disability; that violates
          any (local) equal employment laws, including but not limited to those
          prohibiting the stating, in any advertisement for employment, a
          preference or requirement based on race, color, religion, sex,
          national origin, age, or disability of the applicant. that includes
          personal or identifying information about another person without that
          person's explicit consent;
        </p>{" "}
        <p>
          that impersonates any person or entity, including, but not limited to,
          an TikTakBook employee, or falsely states or otherwise misrepresents
          an affiliation with a person or entity; deceives or misleads the
          addressee about the origin of such messages or communicates any
          information which is grossly offensive or menacing in nature; That is
          false, deceptive, misleading, deceitful, miss-informative, or
          constitutes "bait and switch" offer.
        </p>{" "}
        <p>
          that constitutes or contains "network marketing", "multi-level
          marketing", "Ponzi and pyramid schemes," "affiliate marketing," "link
          referral code," "junk mail," "spam," "chain letters," or unsolicited
          advertisements of a commercial nature; that constitutes or contains
          any form of advertising or solicitation if (1) posted in areas or
          categories of the Website which are not designated for such purposes;
          or (2) e-mailed to TikTakBook users who have requested not to be
          contacted about other services, products or commercial interests;
        </p>{" "}
        <p>
          That includes links to commercial services or Third Party Websites,
          except as specifically allowed by TikTakBook; that advertises any
          illegal services or the sale of any items the sale of which is
          prohibited or restricted by applicable law, including without
          limitation items the sale of which is prohibited or regulated by
          applicable law of local jurisdiction;
        </p>{" "}
        <p>
          that contains software viruses or any other computer code, files or
          programs designed to interrupt, destroy or limit the functionality of
          any computer software or hardware or telecommunications equipment or
          any other computer resource;
        </p>{" "}
        <p>
          That disrupts the normal flow of dialogue with an excessive number of
          messages (flooding attack) to the Service, or that otherwise
          negatively affects other users' ability to use the Service; or That
          employs misleading email addresses, or forged headers or otherwise
          manipulated identifiers in order to disguise the origin of Content
          transmitted through the Service.
        </p>{" "}
        <p>
          <strong> Additionally, you agree not to:</strong>
        </p>{" "}
        <p>
          contact anyone who has asked not to be contacted, or make unsolicited
          contact with anyone for any commercial purpose, specifically, contact
          any user to post advertisement on a third party Website or post any
          advertisement on behalf of such user; or to "stalk" or otherwise
          harass anyone;
        </p>{" "}
        <p>
          Make any libellous or defamatory comments or postings to or against
          anyone;
        </p>{" "}
        <p>
          Collect personal data about other users or entities for commercial or
          unlawful purposes;
        </p>{" "}
        <h2 style={{fontWeight:"400"}}>PAID POSTINGS</h2>
        <p>
          TikTakBook may charge a fee to post Content in some areas of the
          Service. The fee permits certain Content to be post in a designated
          area of the Website. Each party posting Content to the Service is
          responsible for said Content and compliance with the Terms. Any such
          fees paid hereunder are non-refundable in the event any Content is
          remove from the Service for violating these Terms.
        </p>{" "}
        <h2 style={{fontWeight:"400"}}> LICENSE TO ACCESS THE SERVICE </h2>
        <p>
          {" "}
          TikTakBook grants you a limited, revocable, non-exclusive license to
          access and use the Service for personal use. This license granted
          herein does not include any of the following
        </p>
        <p>
          <ol>
            <li>Access to or use of the Service by Posting Agents.</li>
            <li>
              any collection, aggregation, copying, duplication, reproduction,
              display or derivative use of the Service nor any use of data
              mining, robots, spiders, or similar data gathering and extraction
              tools for any purpose unless expressly permitted by TikTakBook or
              as otherwise set forth in these Terms. Notwithstanding the
              foregoing, general purpose internet search engines and
              non-commercial public archives that gather information for the
              sole purpose of displaying hyperlinks to the Service, provided
              they each do so from a stable IP address or range of IP addresses
              using an easily identifiable agent and comply with our robots.txt
              file, may engage in the activities set forth in (b). For purposes
              of this exception, a "general purpose internet search engine" does
              not include a website or search engine or other service that
              specializes in classified listings including any subset of
              classifieds listings such as housing, for sale, jobs, services, or
              personals, or which otherwise provides classified ad listing
              services. The license set forth in this Section permits you to
              display on your website, or create a hyperlink thereto, individual
              postings on the Service so long as such use is for non-commercial
              and/or news reporting purposes only (e.g., for use in personal
              blogs or other personal online media). If the total number of such
              postings displayed on or linked to your website exceeds 99
              postings, your use will be consider to be in violation of these
              Terms, unless TikTakBook expressly grants you permission
              otherwise. You also permitted to create a hyperlink to the home
              page of the Website so long as the link does not portray
              TikTakBook, its employees, affiliates or agents in a false,
              confusing, misleading, derogatory, or otherwise offensive matter.
              TikTakBook may offer parts of the Service in RSS format for the
              purpose of embedding individual RSS feeds into a personal website
              or blog, or view postings through third party software news
              aggregators.
            </li>
          </ol>
        </p>
        <p>
          TikTakBook permits you to display, excerpt from, and link to any such
          RSS feeds on your personal website or personal blow provided that.
        </p>{" "}
        <p>
          <ol>
            <li>
              {" "}
              Your use of the RSS feed is for personal, non-commercial purposes
              only,{" "}
            </li>
            <li>
              Each title within an RSS feed is correctly link back to the
              original post on the Service and redirects the user to the post
              when the user clicks on it
            </li>
            <li>
              You provide, in a conspicuous manner, proper attribution to
              'TikTakBook' as the source of the RSS feed,{" "}
            </li>
            <li>
              Your use or display of the RSS feed does not suggest that
              TikTakBook promotes or endorses any third party causes, opinions,
              ideas, websites, products or services.
            </li>
            <li>You do not redistribute the RSS feed. </li>
            <li>
              Your use does not overburden or otherwise slow the performance of
              TikTakBook's systems
            </li>
          </ol>
        </p>
        <p>
          TikTakBook reserves all rights in and to the content of any RSS feeds
          provided through the Service and may terminate any RSS feed at any
          time without notice. Use of the Service beyond the scope of authorized
          access as set forth in these Terms immediately terminates any
          permission or license granted herein. In order to collect, aggregate,
          copy, duplicate, display or make derivative use of the Service or any
          Content made available via the Service for other purposes (including
          commercial purposes) not stated herein, you must first obtain a
          license from TikTakBook.
        </p>{" "}
        <h2 style={{fontWeight:"400"}}>NOTIFICATION OF CLAIMS OF INFRINGEMENTS</h2>
        <p>
          TikTakBook is not liable for any infringement of copyright or other
          intellectual property rights, arising out of materials posted on or
          transmitted through the site, or items advertised on the site, by end
          users or any other third parties.
        </p>{" "}
        <p>
          If you are an owner of intellectual property rights or an agent who is
          fully authorized to act on behalf of the owner of intellectual
          property rights and believe that any content. Other content infringes
          upon your intellectual property right or intellectual property right
          of the owner on whose behalf you are authorize to act, you may submit
          a notification to TikTakBook together with a request to TikTakBook to
          delete the relevant Content in good faith. The notification and the
          request must contain the following information:
        </p>{" "}
        <p>
          A physical or electronic signature of a person authorized to act on
          behalf of the owner of an exclusive right that is allegedly infringe.
        </p>{" "}
        <p>
          identification of the intellectual property rights claimed to have
          been infringed, or, if multiple intellectual property rights at a
          single online site are covered by a single notification, a
          representative list of such works at that site
        </p>{" "}
        <p>
          identification of the Content (by means of data or communication link,
          AD ID, etc.) that is claimed to be infringing or to be the subject of
          infringing activity and that is to be removed or access to which is to
          be disabled and information reasonably sufficient to permit TikTakBook
          to locate the material;
        </p>{" "}
        <p>
          Information reasonably sufficient to permit TikTakBook to contact you,
          such as an address, telephone number, and, if available, an electronic
          mail address;
        </p>{" "}
        <p>
          a signed statement that you have a good faith belief that use of the
          material in the manner complained of is not authorized by the
          intellectual property right-owner, its agent, or the law;
        </p>{" "}
        <p>
          a signed statement that the intellectual property-owner hold
          TikTakBook harmless from any claim of any third party in connection
          with the removing by TikTakBook of the relevant content; and
        </p>{" "}
        <p>
          Signed statement that the information in the notification is accurate
          and under penalty of perjury that you are authorize to act on behalf
          of the owner of an exclusive right that is allegedly infringe.
        </p>{" "}
        <p>
          Notifications must be send to TikTakBook via legal-mea@TikTakBook.com.
        </p>
        <h2 style={{fontWeight:"400"}}> INTELLECTUAL PROPERTY RIGHTS</h2>
        <p>
          You acknowledge and agree that the materials on the Website, (other
          than the user Content that you licensed to TikTakBook under Section
          2(3) of the Terms). Including without limitation, the text, software,
          scripts, graphics, photos, sounds, music, videos, interactive features
          and the like ("Materials") and the trademarks, service marks and logos
          contained therein ("Marks"), are owned by or licensed to TikTakBook,
          and are subject to copyright and other intellectual property rights
          under the Pakistani laws and international treaties and/or
          conventions. In connection with the Services, the Website may display
          certain trademarks belonging to third parties. Use of these trademarks
          may be subject to license granted by third parties to TikTakBook. You
          shall, in no event, reverse engineer, decompile, or disassemble such
          trademarks and nothing herein shall be construe to grant you any right
          in relation to such trademarks. Materials on the Website are provided
          to you AS IS for your information and personal use only and may not be
          used, copied, reproduced, distributed, transmitted, broadcast,
          displayed, sold, licensed, or otherwise exploited for any other
          purposes whatsoever without the prior written consent of the
          respective owners. TikTakBook reserves all rights not expressly
          granted herein to the Website and the Materials. You agree must no
          engage in the use, copying, reproduction or distribution of any of the
          Materials other than as expressly permitted herein, including any use,
          copying, or distribution of Materials of third parties obtained
          through the Website for any commercial purposes. If you download or
          print a copy of the Materials for personal use, you must retain all
          copyright and other proprietary notices contained therein. You agree
          not to circumvent, disable or otherwise interfere with security
          related features of the Website or features that prevent or restrict
          use or copying of any Materials or enforce limitations on use of the
          Website or the Materials therein. The Service is protect to the
          maximum extent permitted by copyright laws, other laws of Pakistan,
          and international treaties and/or conventions. Content displayed on or
          through the Service is protect by copyright including but not limited
          to as a literary work, a collective work and/or compilation, pursuant
          to copyrights laws, other laws of Pakistan, and international treaties
          and conventions. Any reproduction, modification, creation of
          derivative works from or redistribution of the Website, the Materials,
          or the collective work or compilation, or allowing others to do so, is
          expressly prohibited. Copying or reproducing the Website, the
          Materials, or any portion thereof to any other server or location for
          further reproduction or redistribution is expressly prohibit. You
          further agree not to reproduce, duplicate or copy Content or Materials
          from the Service, and agree to abide by all copyright notices and
          other notices displayed on the Service. You may not decompile or
          disassemble, reverse engineer or otherwise attempt to discover any
          source code contained in the Service. Without limiting the foregoing,
          you agree not to reproduce, duplicate, copy, sell, resell or exploit
          for any commercial purposes, any aspect of the Service. TikTakBook is
          a service mark registered with the U.S. Patent and Trademark Office,
          the EU-wide patent and trademark authorities and in various other
          jurisdictions including Pakistan.
        </p>{" "}
        <h2 style={{fontWeight:"400"}}> USER SUBMISSIONS</h2>
        <p>
          You understand that when using the Website, you will be expose to
          Content from a variety of sources, and that TikTakBook is not
          responsible for the accuracy, usefulness, safety, or intellectual
          property rights of or relating to such, content and you agree and
          assume all liability for your use. You further understand and
          acknowledge that you may be exposed to Content that is inaccurate,
          offensive, indecent, or objectionable, defamatory or libellous and you
          agree to waive, and hereby do waive, any legal or equitable rights or
          remedies you have or may have against TikTakBook with respect thereto.
        </p>{" "}
        <h2 style={{fontWeight:"400"}}> INDEMNITY</h2>
        <p>
          You agree to defend, indemnify and hold harmless TikTakBook, its
          officers, subsidiaries, affiliates, successors, assigns, directors,
          officers, agents, service providers, suppliers and employees, from and
          against any and all claims, damages, obligations, losses, liabilities,
          costs or debt, and expenses (including but not limited to attorneys'
          fees) arising from.
        </p>{" "}
        <p>
          <ul>
            <li>Your use of and access to the Website and/or the Service.</li>
            <li>Your violation of any term of these Terms.</li>
            <li>
              Your violation of any third party right, including without
              limitation any copyright, trademark, and other intellectual
              property rights, trade secret or other property, or privacy right.
            </li>
            <li>
              Any claim that your Content caused damage to a third party. This
              defence and indemnification obligation will survive termination,
              modification or expiration of these Terms and your use of the
              Service and the Website.
            </li>
          </ul>
        </p>
        <h2 style={{fontWeight:"400"}}> NO SPAM POLICY </h2>
        <p>
          {" "}
          You understand and agree that sending unsolicited email advertisements
          or other unsolicited communications to TikTakBook email addresses or
          through TikTakBook, computer systems are expressly prohibit by these
          Terms. You acknowledge and agree that from time to time TikTakBook may
          monitor email usage using human monitors or automated software to flag
          certain words associated with spam or frauds in emails that are send
          between one users to another in the TikTakBook e-mail system. Any
          communication between yourself and any other user utilizing the
          communication features available on the Service and the Website can
          only use only in accordance with the Terms. Any unauthorized use of
          TikTakBook computer systems is a violation of these Terms and certain
          applicable laws. Such violations may subject the sender and his or her
          agents to civil and criminal liabilities and penalties.
        </p>{" "}
        <h2 style={{fontWeight:"400"}}> DEALINGS WITH ORGANIZATIONS AND INDIVIDUALS</h2>
        <p>
          You acknowledge and agree that TikTakBook shall not be liable for your
          interactions with any organizations and/or individuals on the Website
          or through the Service. This includes, but is not limited to, payment
          and delivery of goods and services, and any other terms, conditions,
          warranties or representations associated with any interaction you may
          have with other organizations and/or individuals. These dealings are
          solely between you and such organizations and/or individuals. You
          agree and acknowledge that TikTakBook shall not be responsible or
          liable for any loss or damage of any sort incurred as the result of
          any such dealings or interactions. If there is a dispute between
          participants on the Website, or between users and any third party, you
          understand and agree that TikTakBook is under no obligation to become
          involved in such dispute.
        </p>{" "}
        <p>
          In the event that you have a dispute with one or more other users, you
          hereby release TikTakBook, its officers, employees, agents and
          successors from all claims, demands and damages (actual and
          consequential). Every kind or nature, known or unknown, suspected and
          unsuspected, disclosed and undisclosed, arising out of or in any way
          related to such disputes and/or our Service.
        </p>
        <h2 style={{fontWeight:"400"}}> LIMITATION AND TERMINATION OF SERVICE</h2>
        <p>
          You acknowledge and agree that TikTakBook may establish limits from
          time to time. Concerning use of the Service, including among others.
          The maximum number of days that content will be maintained or retained
          by the Service, the maximum number and size of postings, e-mail
          messages, or other Content that may be transmitted or stored by the
          Service, and the frequency with which you may access the Service or
          the Website. You acknowledge and agree that TikTakBook has no
          responsibility or liability for the deletion or failure to store any
          Content maintained or transmitted by the Website or the Service. You
          acknowledge and agree that TikTakBook reserves the right at any time
          to modify or discontinue the Service (or any part thereof) with or
          without notice, and that TikTakBook shall not be liable to you or to
          any third party for any such modification, suspension or
          discontinuance of the Service. You acknowledge and agree that
          TikTakBook, in its sole and absolute discretion, has the right (but
          not the obligation) to delete or deactivate your account, block your
          e-mail or IP address. Otherwise terminate your access to or use of the
          Service (or any part thereof), immediately and without notice, and
          remove and discard any content within the Service, for any reason or
          no reason at all, including, without limitation, if TikTakBook
          believes that you have violated these Terms. Further, you agree that
          TikTakBook shall not be liable to you or any third party for any
          termination of your access to the Website or the Service. Further, you
          agree not to attempt to use the Service after any such termination.
        </p>{" "}
        <h2 style={{fontWeight:"400"}}>DISCLAIMER OF WARRANTIES</h2>
        <p>
          You expressly acknowledge and agree that use of the website and the
          service is entirely at your own risk and that the website and the
          service are provided on an "as is" or "as available" basis, without
          any warranties of any kind. All express and implied warranties,
          including, without limitation, the warranties of merchantability,
          fitness for a particular purpose, and non-infringement of proprietary
          rights are expressly disclaim fully permitted by law. Fully permitted
          by law TikTakBook, its officers, directors, employees, and agents
          disclaim all warranties, express or implied, in connection with the
          website and your use thereof. TikTakBook makes no warranties or
          representations about the accuracy or completeness of the website's
          content. The content of any third party websites linked to the website
          and assumes no liability or responsibility for any{" "}
        </p>{" "}
        <p>
          <ul>
            <li>Errors, mistakes, or inaccuracies of content.</li>
            <li>
              Personal injury or property damage, of any nature whatsoever,
              resulting from your access to and use of the website and service.
            </li>
            <li>
              Any unauthorized access to or use of our servers and/or all
              personal information and/or financial information stored therein.
            </li>
            <li>
              Any interruption or cessation of transmission to or from the
              website.
            </li>
            <li>
              Any bugs, viruses, Trojan horses, or the like which may be
              transmitted to or through the website by any third party, and/or
            </li>
            <li>
              Any errors or omissions in any content or for any loss or damage
              of any kind incurred because of the use of any content posted,
              emailed, communicated, transmitted, or otherwise made available
              via the website or the service
            </li>
          </ul>
        </p>{" "}
        <p>
          {" "}
          TikTakBook does not warrant, endorse, guarantee, or assume
          responsibility for any product or service advertised or offered by a
          third party through the website. Any hyperlinked website or featured
          in any banner or other advertising, and TikTakBook will not be a party
          to or in any way be responsible for monitoring any transaction between
          you and/or other users and/or third-party providers of products or
          services. As with the purchase of a product or service through any
          medium or in any environment, you should use your best judgment and
          exercise caution where appropriate
        </p>
        <h2 style={{fontWeight:"400"}}> LIMITATIONS OF LIABILITY</h2>
        <p>
          {" "}
          In no event shall TikTakBook, its officers, directors, employees, or
          agents, be liable for direct, indirect, incidental, special,
          consequential or exemplary damages (even if TikTakBook has been
          advised of the possibility of such damages). Resulting from any aspect
          of your use of the website or the service, including without
          limitation whether the damages arise from use or misuse of the website
          or the service, from inability to use the website or the service, or
          the interruption, suspension, modification, alteration, or termination
          of the website or the service. Such limitation of liability shall also
          apply with respect to damages incurred by reason of other services or
          products received through or advertised in connection with the website
          or the service or any links on the website. As well as because of any
          information, opinions or advice received through or advertised in
          connection with the website or the service or any links on the
          TikTakBook site. These limitations shall apply fully permitted by law.
          You specifically acknowledge and agree that TikTakBook shall not be
          liable for user submissions or the defamatory, offensive, or illegal
          conduct of any user or third party and that the risk of harm or damage
          from the foregoing rests entirely with you. The website is controlled
          and offer by TikTakBook. TikTakBook makes no representations or
          warranties that the website is appropriate for use in other locations.
          Those who access or use the website from other jurisdictions do so at
          their own volition and risk and are responsible for compliance with
          local law.
        </p>
        <h2 style={{fontWeight:"400"}}> ASSIGNMENT</h2>
        <p>
          {" "}
          These Terms, and any rights and licenses granted hereunder, must not
          be transferred or assigned by you, but may be assigned by TikTakBook
          without restriction. Any assignment or transfer by you shall be
          invalid.
        </p>
        <h2 style={{fontWeight:"400"}}> ABILITY TO ACCEPT TERMS OF SERVICE</h2>
        <p>
          This Website is intend only for adults and that you are eligible to
          contract as per applicable laws. If you are using/accessing this
          Website as a representative of any person/entity, you acknowledge that
          you are legally authorize to represent that person/entity. Minors,
          i.e. users of under 18 years of age, are only allow to access the
          Website and use the Service, in the event of approval of their legal
          representatives or in the event that it concerns an act or a
          transaction that is usual and acceptable standard in civil life and
          practice. You affirm that you are either at least 18 years of age, or
          an emancipated minor, or possess legal parental or guardian consent,
          and are fully able and competent to enter into the terms, conditions,
          obligations, affirmations, agreements, representations, and warranties
          set forth in these Terms, and to abide by and comply with these Terms.
          In any case, you affirm that you are over the age of 13, as the
          Website is not intend for children under 13.
        </p>
        <p>
          <strong> Notice : </strong> to children under the age of 13 and their
          parents or guardians if you are under the age of 13, you must not use
          this website. Please do not send us your personal information,
          including your email addresses, name, and/or contact information. If
          you want to contact us, you may only do so through your parent or
          legal guardian.
        </p>
        <h2 style={{fontWeight:"400"}}>GENERAL INFORMATION</h2>
        <p>
          {" "}
          These Terms and the other policies posted on the Website constitute
          the complete and exclusive understanding and agreement between you and
          TikTakBook and govern your use of the Service and the Website
          superseding all prior understandings, proposals, agreements,
          negotiations, and discussions between the parties, whether written or
          oral. The laws of Pakistan shall govern the Terms and the relationship
          between you and TikTakBook. Any claim you may have against TikTakBook
          must be submit to the exclusive jurisdiction of the courts of Lahore,
          Pakistan. However, in the event that you are a consumer it may be that
          consumer law requires that another law is applicable and that a claim
          may be submit to another jurisdiction. The failure of TikTakBook to
          exercise or enforce any right or provision of the Terms shall not
          constitute a waiver of such right or provision. If any provision of
          the Terms is found by a court of competent jurisdiction to be invalid
          (including, without limitation, because such provision is inconsistent
          with the laws of another jurisdiction) or inapplicable, the parties
          nevertheless agree that the court should endeavour to give effect to
          the parties' intentions as reflected in the provision. If any
          provision or provisions of these Terms is held to be invalid, illegal
          or unenforceable, the validity, legality and enforceability of the
          remaining provisions of the Terms shall not in any way be affected or
          be impaired. These Terms shall inure to the benefit of and be binding
          upon each party's successors and assigns.{" "}
        </p>
        <h2 style={{fontWeight:"400"}}> VIOLATION OF TERMS AND LIQUIDATED DAMAGES</h2>
        <p>
          Please report any violations of the Terms that you become aware of by
          contacting us using the link at the bottom of the TikTakBook homepage
          at TikTakBook.com.pk. Any failure to act by TikTakBook with respect to
          a breach by you or others does not waive our right to act with respect
          to subsequent or similar breaches by you or others. Notwithstanding
          the previously mentioned clauses regarding any other provision of
          these Terms, TikTakBook retains the right to seek legal. Equitable
          remedies, including without limitation, specific performance of any
          term contained in these Terms or a preliminary or permanent injunction
          against the breach or threatened breach of any such term or in aid of
          the exercise of any power granted in these Terms, or any combination
          thereof, without the necessity of posting a bond.
        </p>
        <h2 style={{fontWeight:"400"}}>HOW TO SELL A BOOK</h2>
        <p>
          {" "}
          At TikTakBook, selling, exchanging or donating a book is very easy.
          Just make a post, select appropriate option and click Submit.
        </p>
      </div>
      {/* <Footer history={props.history}/> */}
    </div>
  );
}
