import * as firebase from 'firebase';   

// Initialize Firebase
var firebaseConfig = {
    apiKey: "AIzaSyAbk_j-Ru3NWpjxD361SI_iJi0V8bqVR4A",
    authDomain: "sale-and-buy.firebaseapp.com",
    databaseURL: "https://sale-and-buy.firebaseio.com",
    projectId: "sale-and-buy",
    storageBucket: "sale-and-buy.appspot.com",
    messagingSenderId: "280937036832",
    appId: "1:280937036832:web:2c6668c9c2518839b8d045",
    measurementId: "G-DF53EP0NYM"
  };
  firebase.initializeApp(firebaseConfig);

  //export db from here for using it in all page like im using firestore so for acceccing firestore in all pages we use db , 
  export default firebase;