import React from "react";
import "./style.css";
import Logo from "../../assets/icons/logo.png";
// import { Button, Link } from "@material-ui/core";
// import IconButton from "@material-ui/core/IconButton";
// import Info from "../../components/infobar/info";
import { NavLink } from "react-router-dom";

export default class MainFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <footer class="page-footer container-fluid">
        <div>
          <div class="row footer-text">
            <div class="col-md-4 col-lg-3 mr-auto my-md-4 my-0 mt-4 mb-1 imglogo ">
              <img src={Logo} style={{ width: 220 }} />
              <br />
              <br />
              <p>
                TikTakBook is perhaps one of the world’s only mobile application
                that is focus on exchange, buy & sell and donation of Books with
                one of the main aim to conserve forests and trees. At TikTakBook
                we please ticking and tacking to exchange the used book, saving
                time, natural resources and of course money.
              </p>
            </div>

            <hr class="clearfix w-100 d-md-none" />

            <div class="col-md-2 col-lg-2 mx-auto my-md-4  Link-area">
              <h5
                class="font-weight-bold text-uppercase mb-5"
                style={{ color: "#484ec8" }}
                id="heading5"
              >
                Usefull links
              </h5>
              <ul class="list-styled">
                <li>
                  <NavLink className="a" to="/">Home</NavLink>
                </li>

                <li>
                  <NavLink className="a" to="/about">About us</NavLink>
                </li>

                <li>
                  <NavLink className="a" to="/contact">Contact Us</NavLink>
                </li>
                <li>
                  <NavLink className="a" to="/Privacy_Policy">Privacy Policy</NavLink>
                </li>
              </ul>
            </div>

            <hr class="clearfix w-100 d-md-none" />
            <div class="col-md-4 col-lg-3 mx-auto my-md-4  address-area">
              <h5
                class="font-weight-bold text-uppercase mb-4"
                style={{ color: "#484ec8" }}
                id="heading5"
              >
                Address
              </h5>

              <ul class="list-style">
                <h5>
                  <img
                    src={require("../../assets/icons/Subtraction 3.png")}
                    width="6%"
                  />{" "}
                  Address
                </h5>
                <p>Plot # 11-C Lane 7 Phase 7 DHA Karachi</p>

                <h5>
                  <img
                    src={require("../../assets/icons/envelope.png")}
                    width="6%"
                  />{" "}
                  Email
                </h5>
                <p>info@Tiktakbook.com</p>

                <h5>
                  <img
                    src={require("../../assets/icons/phone-receiver.png")}
                    width="6%"
                  />{" "}
                  Phone
                </h5>
                <p>+ 92 334 3569118</p>
              </ul>
            </div>
          </div>
          <hr />

          <div class="footer-copyright text-left">
            <a href="/"> © 2019 Tiktakbook All right reserved</a>
            <div class="grow" />
            <div class="sectionDesktop">
              <a
                href="https://www.facebook.com/Tiktak-Book-102699547892095/"
                target="blank"
              >
                <img src={require("../../assets/icons/facebook.png")} />
              </a>
              {/* <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <img src="https://img.icons8.com/color/24/000000/instagram.png" />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
              >
                <img src="https://img.icons8.com/material-outlined/24/000000/twitter-squared.png" />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
              >
                <img src="https://img.icons8.com/material-rounded/24/000000/globe--v2.png"></img>
              </IconButton> */}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
