import React from "react";
import axios from "axios";
import Navbar from "react-bootstrap/Navbar";
import { Nav, NavDropdown, Container, Link } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import "./header.css";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Slide from "@material-ui/core/Slide";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Fade from "@material-ui/core/Fade";
import Login from "../../screens/login/login";
import { NavLink } from "react-router-dom";
import history from "../../history";
import {
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from "react-scroll";

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Add_title: "",
      imagePreviewUrl: null,
      BookTitle: "",
      AuthorName: "",
      SellingPrice: "",
      Grade: "",
      Subject: "",
      Exchange: "",
      Description: "",
      Condition: "",
      PaperQuality: "",
      Donation: "",
      copy: "",
      open: false,
      Image: null,
      Images: [],
      images: [],
      modal: false
    };
    this.addImage = this.addImage.bind(this);
    this.updateImg = this.updateImg.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  OpenModel = () => {
    this.setState({
      open: true
    });
  };

  CloseModel = () => {
    this.setState({ open: false });
  };
  Model = () => {
    this.setState({ modal: true });
  };
  ModelC = () => {
    this.setState({ modal: false });
  };

  addImage(evt) {
    evt.preventDefault();
    let { Images, images, Image, imagePreviewUrl } = this.state;

    Images.push({ img: Image });
    images.push({ preview: imagePreviewUrl });
    this.setState({ Images: Images });
    this.setState({ imagePreviewUrl: "" });
  }
  updateImg(evt) {
    evt.preventDefault();
    this.setState({
      imagePreviewUrl: URL.createObjectURL(evt.target.files[0])
    });
    this.setState({ Image: evt.target.files[0] });
  }

  onSubmit() {
    let {
      BookTitle,
      AuthorName,
      SellingPrice,
      Condition,
      Grade,
      Subject,
      Donation,
      PaperQuality,
      copy,
      Exchange,
      Description,
      Images,
      images,
      Image,
      Add_title
    } = this.state;
    const datanow = new Date();
    const data = new FormData();
    Images.map(val => {
      data.append("thumbnail[]", val.img);
    });
    data.append("b_title", BookTitle);
    data.append("author_name", AuthorName);
    data.append("price", SellingPrice);
    data.append("class_name", Grade);
    data.append("subject", Subject);
    data.append("exchange", Exchange);
    data.append("des", Description);
    data.append("condition", Condition);
    data.append("donation", Donation);
    data.append("p_q", PaperQuality);
    data.append("o_p", copy);
    data.append("time", datanow);
    data.append("title", Add_title);
    axios
      .post("https://deaplearning.com/admin/app/api/ads/store", data, {})
      .then(s => {
        console.log(s, "resposse"); // do something with the response
      });
    if (
      BookTitle &&
      AuthorName &&
      SellingPrice &&
      Condition &&
      Grade &&
      Subject &&
      Donation &&
      PaperQuality &&
      copy &&
      Exchange &&
      Description &&
      Images &&
      images &&
      Image &&
      Add_title
    ) {
      // this.setState({
      //   BookTitle: "",
      //   AuthorName: "",
      //   SellingPrice: "",
      //   Grade: "",
      //   Subject: "",
      //   Exchange: "",
      //   Description: "",
      //   Condition: "",
      //   copy: "",
      //   donation: "",
      //   PaperQuality: "",
      //   Donation: "",
      //   Images: [],
      //   images: [],
      //   Add_title: "",
      //   open: false
      // });
    } else alert("ALL Field Require");
  }

  render(props) {
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} width="60%" />;
    }
    return (
      <>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
          sticky="top"
        >
          <a href={"/"}>
            <img
              src={require("../../assets/icons/logo.png")}
              style={{ width: 140 }}
            />
          </a>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto" id="nav_link">
              <NavLink
                className="nav-link"
                to="/"

                // onClick={() => this.props.history.push("/")}
              >
                Home
              </NavLink>
              <NavLink to="/about" className="nav-link">
                About us
              </NavLink>
              <NavLink
                to="/category"
                className="nav-link"
                // onClick={() => this.props.history.push("/category")}
              >
                Categories
              </NavLink>

              <NavLink
                to="/contact"
                className="nav-link"
                // onClick={() => this.props.history.push("/Contact")}
              >
                Contact us
              </NavLink>
              <div class="app-download-btn2" data-wow-delay="0.2s">
                <a
                  href="https://play.google.com/store/apps/details?id=com.tiktakbook"
                  target="blank"
                >
                  <img
                    src={require("../../assets/icons/playstore.png")}
                    style={{ width: 30, marginLeft: -15 }}
                    className="play"
                  />
                  <p class="mb-0">
                    <span>available on</span> Google Store
                  </p>
                </a>
              </div>
            </Nav>
            <div className="res-icon">
              <Nav id="nav-button">
                {/* <div
                  class="app-download-btn2"
                  data-wow-delay="0.2s"
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=com.tiktakbook"
                    target="blank"
                  >
                    <img
                      src={require("../../assets/icons/playstore.png")}
                      // style={{ width: 30, marginLeft: -15 }}
                      className="play"
                    />
                    <p class="mb-0">
                      <span>available on</span> Google Store
                    </p>
                  </a>
                </div> */}

                {/* <Button
                  style={{
                    width: 130,
                    color: "#8A75F8",
                    fontSize: 15,
                    height: 40,
                    background: "none"
                  }}
                  onClick={() => this.props.history.push("/login")}
                  id="btn"
                >
                  Login
                </Button> */}
                {/* <Button
                  style={{
                    width: 130,
                    color: "#8A75F8",
                    height: 40,
                    borderLeft: "2px solid #8A75F8",
                    borderRadius: "0",
                    background: "none"
                  }}
                  id="btn1"
                >
                  <img
                    src={require("../../assets/icons/search-1.png")}
                    width="25%"
                    className="ser1"
                  />
                </Button> */}
                {/* <Button
                  style={{
                    color: "#8A75F8",
                    fontSize: 15,
                    height: 40,
                    borderLeft: "2px solid #8A75F8",
                    borderRadius: "0",
                    background: "none"
                  }}
                  onClick={this.OpenModel}
                  id="btn"
                >
                  <img
                    src={require("../../assets/icons/photo-camera.png")}
                    width="40%"
                    style={{ paddingRight: 10 }}
                    className="ser"
                  />
                  SELL
                </Button> */}
              </Nav>
            </div>
          </Navbar.Collapse>
        </Navbar>

        <Modal
          className="model1"
          open={this.state.open}
          onClose={this.CloseModel}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          {/* <Slide
            direction="right"
            in={this.state.open}
            mountOnEnter
            unmountOnExit
          > */}

          <div className="paper-model">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6 pic-grid">
                  <div className="root">
                    <GridList cellHeight={180} className="gridList">
                      {this.state.images.map(val => (
                        <GridListTile key={val.preview} cols={val.cols || 1}>
                          <img src={val.preview} width="100%" />
                        </GridListTile>
                      ))}
                    </GridList>
                  </div>

                  <br />
                  <br />
                  <div>
                    <Input
                      accept="image/*"
                      onChange={this.updateImg}
                      id="file-button"
                      multiple
                      type="file"
                    />
                    <br />
                    <br />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        component="span"
                        onClick={this.addImage}
                      >
                        Upload
                      </Button>
                    </label>
                    <br />
                    <div>{$imagePreview}</div>
                  </div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <Button
                      style={{
                        background: "linear-gradient(40deg, #484ec8, #e66cdb)",
                        color: "white",
                        width: 90,
                        height: 40
                      }}
                      onClick={this.onSubmit}
                    >
                      {" "}
                      SELL
                    </Button>
                  </div>
                </div>
                <div class="col-md-6">
                  {/* <img
                    src={require("../../assets/icons/logo.png")}
                    width="25%"
                  /> */}

                  <div>
                    <TextField
                      fullwidth
                      id="outlined"
                      label="Add Title"
                      margin="normal"
                      variant="outlined"
                      required={true}
                      value={this.state.Add_title}
                      onChange={e =>
                        this.setState({ Add_title: e.target.value })
                      }
                    />

                    <TextField
                      fullwidth
                      id="outlined"
                      label="Book Title"
                      margin="normal"
                      variant="outlined"
                      required={true}
                      value={this.state.BookTitle}
                      onChange={e =>
                        this.setState({ BookTitle: e.target.value })
                      }
                    />
                    <TextField
                      fullwidth
                      id="outlined"
                      label="Auther Name"
                      margin="normal"
                      variant="outlined"
                      required={true}
                      value={this.state.AuthorName}
                      onChange={e =>
                        this.setState({ AuthorName: e.target.value })
                      }
                    />
                    <TextField
                      fullwidth
                      id="outlined"
                      label="Selling Price"
                      margin="normal"
                      variant="outlined"
                      type="number"
                      required={true}
                      value={this.state.SellingPrice}
                      onChange={e =>
                        this.setState({ SellingPrice: e.target.value })
                      }
                    />
                  </div>
                  <br />
                  <div>
                    <FormControl variant="outlined" className="formcontrol">
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        style={{ fontSize: 17 }}
                      >
                        Grade
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        labelWidth={60}
                        value={this.state.Grade}
                        onChange={e => this.setState({ Grade: e.target.value })}
                      >
                        <MenuItem value="1st">1st</MenuItem>
                        <MenuItem value="2nd">2nd</MenuItem>
                        <MenuItem value="3rd">3rd</MenuItem>
                        <MenuItem value="4th">4th</MenuItem>
                        <MenuItem value="5th">5th</MenuItem>
                        <MenuItem value="6th">6th</MenuItem>
                        <MenuItem value="7th">7th</MenuItem>
                        <MenuItem value="8th">8th</MenuItem>
                        <MenuItem value="9th">9th</MenuItem>
                        <MenuItem value="10th">10th</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl variant="outlined" className="formcontrol2">
                      <InputLabel id="demo-simple">Subjects</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        labelWidth={60}
                        value={this.state.Subject}
                        onChange={e =>
                          this.setState({ Subject: e.target.value })
                        }
                      >
                        <MenuItem value="Mathmatics">Mathmatics</MenuItem>
                        <MenuItem value="English">English</MenuItem>
                        <MenuItem value="Urdu">Urdu</MenuItem>
                        <MenuItem value="Islamiat">Islamiat</MenuItem>
                        <MenuItem value="pak-tudies">pak-studies</MenuItem>
                        <MenuItem value="Chemistry">Chemistry</MenuItem>
                        <MenuItem value="Computer">Computer</MenuItem>
                        <MenuItem value="Biology">Biology</MenuItem>
                        <MenuItem value="Zology">Zology</MenuItem>
                        <MenuItem value="Physics">Physics</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <br />
                  <FormControl variant="outlined" className="formcontrol3">
                    <InputLabel id="demo-simple" style={{ fontSize: 17 }}>
                      {" "}
                      Exchange ?
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      labelWidth={80}
                      value={this.state.Exchange}
                      onChange={e =>
                        this.setState({ Exchange: e.target.value })
                      }
                    >
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                      <MenuItem value="NotSure">NotSure</MenuItem>
                    </Select>
                  </FormControl>
                  <br />
                  <TextField
                    fullwidth
                    id="outlined"
                    label="Discribe what you're selling"
                    margin="normal"
                    variant="outlined"
                    multiline={true}
                    rows="4"
                    value={this.state.Description}
                    onChange={e =>
                      this.setState({ Description: e.target.value })
                    }
                  />
                  <br />
                  <br />
                  <FormControl variant="outlined" className="formcontrol3">
                    <InputLabel id="demo-simple" style={{ fontSize: 17 }}>
                      {" "}
                      Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      labelWidth={80}
                      value={this.state.Condition}
                      onChange={e =>
                        this.setState({ Condition: e.target.value })
                      }
                    >
                      <MenuItem value="New">New</MenuItem>
                      <MenuItem value="Used">Used</MenuItem>
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <FormControl variant="outlined" className="formcontrol3">
                    <InputLabel id="demo-simple" style={{ fontSize: 17 }}>
                      {" "}
                      Paper Quality
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      labelWidth={80}
                      value={this.state.PaperQuality}
                      onChange={e =>
                        this.setState({ PaperQuality: e.target.value })
                      }
                    >
                      <MenuItem value="Heavy">Heavy</MenuItem>
                      <MenuItem value="Light">Light</MenuItem>
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <div>
                    <RadioGroup
                      aria-label="position"
                      name="position"
                      value={this.state.copy}
                      onChange={e => this.setState({ copy: e.target.value })}
                      row
                    >
                      <FormControlLabel
                        value="Original"
                        control={<Radio color="primary" />}
                        label="Original"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="Pirated"
                        control={<Radio color="primary" />}
                        label="Pirated"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </div>
                  <FormControl variant="outlined" className="formcontrol3">
                    <InputLabel id="demo-simple" style={{ fontSize: 17 }}>
                      {" "}
                      Donation ?
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      labelWidth={80}
                      value={this.state.Donation}
                      onChange={e =>
                        this.setState({ Donation: e.target.value })
                      }
                    >
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                      <MenuItem value="NotSure">NotSure</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
          {/* </Slide> */}
        </Modal>
      </>
    );
  }
}
