import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Button from "@material-ui/core/Button";
import GoogleLogin from "react-google-login";
import { GoogleLogout } from "react-google-login";
import TextField from "@material-ui/core/TextField";
import "./login.css";
import firebase from "../../config/firbase";
import Otpcode from "./Otpcode";

export default class SignUpTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignedIn: false,
      phoneSignup: "",
      randerinfo: false,
      randerotp: false
    };
  }
  
  onPhone = () => {
    const phoneNumber = this.state.phoneSignup;
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(r => {
        // success
        console.log(r, "hello");
        this.setState({change:true})
      })
      .catch(error => {
        // error
      });
  };
  randerPhone = () => {
    const responseGoogle = response => {
      console.log(response, "login");
    };

    return (
      <form class="form">
        <div class="p-4">
          <PhoneInput
            value={this.state.phoneSignup}
            onChange={phoneSignup => this.setState({ phoneSignup })}
            required
            id="input"
            placeholder="Enter phone number"
          />
          <br />
          <Button
            class="btn btn-outline-white btn-rounded"
            id="recaptcha-container"
            onClick={this.onPhone}
          >
            Next
          </Button>
          <br />
          <br />
          <div class="links">
            <p> Dont have an acoount ?</p>
            <a href="#">Login.</a>
          </div>
        </div>
       
      </form>
    );
  };
  randerInfo = () => {
    return (
      <form class="form">
        <div class="p-4">
          <TextField
            id="filled-basic"
            className="textfield"
            label="FullName"
            margin="normal"
            // variant="filled"
            required={true}
          />
          <TextField
            id="filled-basic"
            className="textfield"
            label="Email"
            margin="normal"
            // variant="filled"
            required={true}
          />
          <br />
          <br />
          <Button
            class="btn btn-outline-white btn-rounded "
            type="next"
            id="btn-next"
            // onClick={this.onPhoneChange}
          >
            Next
          </Button>
          <br />
          <br />
          <br />
        </div>
       
      </form>
    );
  };

  render() {
    const responseGoogle = response => {
      console.log(response, "login");
    };
    let { randerinfo, randerotp } = this.state;
    return (
      <div style={{ textAlign: "center" }}>
        {randerotp ? 
          <Otpcode />
         : 
          this.randerPhone()
        }
      </div>
    );
  }
}
