import React, { useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Header from "../../components/header/header";
import { green } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Button from "@material-ui/core/Button";
import OTPInput, { ResendOTP } from "otp-input-react";
import GoogleLogin from "react-google-login";
import { GoogleLogout } from "react-google-login";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import "./login.css";
import firebase from "../../config/firbase";
import LoginTab from "./loginTab";
import SignUpTab from "./SignupTab";
import Catta from "../../screens/categories/index";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      history={props.history}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "white",
    width: "100%",
    position: "relative"
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },

  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600]
    },
    container: {
      backgroundColor: "white",
      border: "1px solid black"
    }
  },
  appbar: {
    border: "none",
    background: "white",
    boxShadow: "none",
    width: "80%",
    margin: "0 auto"
  },
  tabs: {
    fontSize: 20,
    height: 70,
    textTransform: "capitalize"
  },
  header: {
    width: "100%",
    backgroundColor: "#ebebeb"
  },
  main: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignSelf: "center",
    padding: 100
  },
  Img: {
    padding: 30,
    width: 600
  }
}));

export default function Login(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <a href="" onClick={() => this.props.history.push("/")}>
          <img src={require("../../assets/Logo.png")} style={{ width: 90 }} />
          <img src={require("../../assets/Logo1.png")} style={{ width: 100 }} />
        </a>
      </div>
      <div className={classes.main}>
        <div className={classes.Img}>
          <img
            src="https://media3.giphy.com/media/EyYYkV1bqr8Mo/source.gif"
            style={{
              width: "100%",
              height: 400,
              boxShadow: "4px 4px 20px black"
            }}
          />
        </div>
        <div id="main-div">
          <LoginTab />
          <br />
          {/* <AppBar
            position="static"
            color="inherit"
            colorDefault="white"
            className={classes.appbar}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="action tabs example"
            >
              <Tab
                label="Login"
                {...a11yProps(0)}
                className={classes.tabs}
                id="tab-value1"
              />
              <Tab
                label="Signup"
                {...a11yProps(1)}
                className={classes.tabs}
                id="tab-value"
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <LoginTab />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <SignUpTab history={props.history} />
            </TabPanel>
          </SwipeableViews> */}
        </div>
      </div>
    </div>
  );
}
