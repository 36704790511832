import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../components/header/header";
import history from "../../history";
import Footer from "../../components/footer/index";
import "./pri.css";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "white",
    padding: 50,
    paddingTop:0,
    width: "100%",
    position: "relative",
    textAlign:"justify"
  }
}));

export default function About(props) {
  const classes = useStyles();
  return (
    <div className="fluid">
      <div className="mainFeaturedPost">
        <Grid container>
          <Grid item md={6}>
            <div className="mainFeaturedPostContent">
              <Typography
                component="h2"
                variant="h2"
                color="inherit"
                gutterBottom
                className="Header-h1"
              >
                About us
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.root} >
        <h2 style={{ fontWeight: "400" }}>ABOUT TIKTAKBOOK</h2>{" "}
        <p>
          TikTakBook is the next generation of free online classifieds. We act
          as an online marketplace platform to allow our users who comply with
          these Terms to offer, sell, and buy products and services listed on
          the Website. Although you may be able to conduct payment and other
          transactions through the Website, using third-party vendors such as
          PayPal. TikTakBook is not in any way involved in such transactions. As
          a result, and as discussed in more detail in these Terms, you hereby
          acknowledge and agree that TikTakBook is not a party to such
          transactions, has no control over any element of such transactions,
          and shall have no liability to any party in connection with such
          transactions. You use the Service and the Website at your own risk.
          You understand that TikTakBook does not control, and is not
          responsible for ads, directory information, business
          listings/information, messages between users. Including without
          limitation e-mails sent from outside TikTakBook domain or other means
          of electronic communication. Whether through the Website or another
          Third Party Website (defined below) or offerings, comments, user
          postings, files, images, photos, video, sounds, business
          listings/information and directory information or any other material
          made available through the Website and the Service ("Content").
        </p>
        <p>
          {" "}
          I want to inform you that whenever you use my Service, in a case of an
          error in the app I collect data and information (through third party
          products) on your phone called Log Data. This Log Data may include
          information such as your device Internet Protocol (“IP”) address,
          device name, operating system version, the configuration of the app
          when utilizing my Service, the time and date of your use of the
          Service, and other statistics. That by using the Website and the
          Service, you may be expose to Content that is offensive, indecent,
          inaccurate, misleading, or otherwise objectionable. You acknowledge
          and agree that you are responsible for and must evaluate, and bear all
          risks associated with, the use of any content that. You may not rely
          on said content, and that under no circumstances will TikTakBook be
          liable in any way for the content. For any loss or damage of any kind
          incurred because of the browsing, using or reading any content listed,
          e-mailed or otherwise made available via the Service.
        </p>
        <p>
          {" "}
          You acknowledge and agree that TikTakBook permits such goods and
          services to be display and offered on the Website that confirm with
          the terms stated herein. However, TikTakBook does not pre-screen or
          approve any Content, but that TikTakBook has the right, in its sole
          and absolute discretion, to refuse, delete or move any Content that is
          or may be available through the Service, for violating these Terms and
          such violation being bring to TikTakBook’s knowledge or for any other
          reason or no reason at all. Furthermore, the Website and Content
          available through the Website may contain links to other third party
          websites ("Third Party Websites"), which are completely unrelated to
          TikTakBook. If you link to Third Party Websites, you may be subject to
          those Third Party Websites’ terms, conditions, and other policies.
          TikTakBook makes no representation or guarantee as to the accuracy or
          authenticity of the information contained in any such Third Party
          Website, and your linking to any other websites is completely at your
          own risk and TikTakBook disclaims all liability thereto. You
          acknowledge and agree that you are solely responsible for your own
          Content posted on, transmitted through, or linked from the Service and
          the consequences of posting, transmitting, linking or publishing it.
          More specifically, you are solely responsible for all Content that you
          upload, email or otherwise make available via the Service. In
          connection with such Content posted on, transmitted through, or linked
          from the Service by you. You affirm, acknowledge, represent, warrant
          and covenant that. You have developed the contents such as description
          and/or photographs in accordance with the instructions and guidance
          for placing an ad provided by TikTakBook on the website and that any
          copyrights therein belongs to TikTakBook and, to any extent, should
          you be deemed to own any copyright you hereby assign any such
          copyright to TikTakBook You acknowledge that the information and/or
          photographs that you post on the Website is stored and compile by
          TikTakBook in a proprietary database and that TikTakBook modifies the
          uploaded information and/or photographs by applying a watermark of
          TikTakBook logo and in such derivate works, the copyright belongs to
          TikTakBook. You shall continue to, for such time the Content is
          available on the Website, have the necessary licenses, authorizations,
          consents, and permissions to use such Content on the Service and
          Website (including without limitation all patent,
        </p>
        <p>
          {" "}
          trademark, trade secret, copyright or other proprietary rights in and
          to any and all such Content). Wherever required, in such cases where
          you have the right, hereby authorize and license TikTakBook to
          exclusive use such Content to enable inclusion and use of the Content
          in the manner contemplated by the Service, the Website and these You
          have the written consent, release, and/or permission of each
          identifiable individual person or business in the Content to use the
          name or likeness of each such identifiable individual person or
          business to enable inclusion and use of the Content in the manner
          contemplated by the Service, the Website and these Terms. For clarity,
          by submitting any content on the Website, as previously mentioned.
          Wherever you retain any ownership rights in the Content, you hereby
          grant to TikTakBook an irrevocable, non-cancellable, perpetual,
          worldwide, exclusive, royalty-free, sub-licensable, transferable
          license to use, reproduce, distribute, and prepare derivative works
          of, display. Perform the Content in connection with the Website and
          TikTakBook's (and its successors') business, including without
          limitation for the purpose of promoting and redistributing part or the
          entire Website Content therein (and derivative works thereof) in any
          media formats and through any media channels now or hereafter known.
          Furthermore, by you posting content to any public area of the Service,
          to the extent you retain any ownership of any rights. You agree to and
          do hereby grant to TikTakBook all exclusive rights necessary to
          prohibit or allow any subsequent aggregation, display, copying,
          duplication, reproduction, or exploitation of the Content on the
          Service or Website by any party for any purpose including the right to
          initiate legal action in accordance with the copyright and other laws
          of Pakistan. You also hereby grant each user of the Website a
          non-exclusive license to access you are Content through the Website.{" "}
        </p>{" "}
        <p>
          The foregoing license to each user granted by you terminates once you
          or TikTakBook remove or delete such Content from the Website.
          TikTakBook does not endorse any Content or any opinion, statement,
          recommendation, or advice expressed therein, and TikTakBook expressly
          disclaims all liability in connection with user Content. TikTakBook
          does not permit copyright infringing activities and infringement of
          intellectual property rights on the Website, and TikTakBook may at its
          sole discretion, remove any infringing Content if properly notified in
          accordance with applicable law that such Content infringes on
          another's intellectual property rights. TikTakBook reserves the right
          to remove any Content without prior notice. TikTakBook may also
          terminate a user's access to the Website, if they are determined to be
          a repeat infringer or found to be indulging in any act contrary to
          these Terms. A repeat infringer is a user who has been notified of
          infringing activity more than twice and/or has had a user submission
          removed from the Website more than twice. Further, at its sole
          discretion, TikTakBook reserves the right to decide whether any
          Content is, appropriate and complies with these Terms. TikTakBook may
          provide a video service to you if you upload photographs for your ad.
          By uploading your photographs, not covered by 2(3) (I) or (ii) above,
          you irrevocably license and consent to the exclusive use of your
          photographs for this service and represent and warrant that you have
          all right, title and interest necessary to upload and use the
          photographs on this service. Under this service, using a third party
          application or service, TikTakBook may, at its discretion, create a
          video of your photographs, upload the video via www.youtube.com or
          another third party service provider, as determined by TikTakBook at
          its discretion, and embed the video into your ad on TikTakBook.
        </p>{" "}
      </div>
      {/* <Footer history={props.history} /> */}
    </div>
  );
}
