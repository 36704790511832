import React from "react";
import logo from "./logo.svg";
import "./App.css";
// import Routes from './config/router';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./screens/home/home";
// import Login from ".screens/login/login";
import Categories from "./screens/categories/index";
import Post from "./screens/Postpreview/postpreview";
import history from "./history";
import Otp from "./screens/login/Otpcode";
import About from "./screens/contact/about";
import Topcard from "./components/cardsection/Topcard";
import Signup from "./screens/login/SignupTab";
import Privacy from "./screens/contact/Privacy Policy";
import Search from "./screens/SearchData/searchScreen";
import Contact from "./screens/contact/contact";
import Header from "./components/header/header";
import loader from "./components/Skeleton/loader";
import Footer from "./components/footer/index";
import ScrollToTopRoute from "./components/Scroll/scrolltotop";

export default class App extends React.Component {
  render() {
    return (
      <div>
        <Router history={history}>
          <Header history={history} />
          <Route exact path="/" component={Home} />
          <ScrollToTopRoute exact path="/category" component={Categories} />
          <ScrollToTopRoute exact path="/about" component={About} />
          <ScrollToTopRoute exact path="/contact" component={Contact} />
          <ScrollToTopRoute path="/postpre/:itemid" component={Post} />
          <ScrollToTopRoute exact path="/otp" component={Otp} />
          <ScrollToTopRoute exact path="/card/:setitle" component={Topcard} />
          <ScrollToTopRoute exact path="/signup" component={Signup} />
          <ScrollToTopRoute exact path="/Privacy_Policy" component={Privacy} />
          <ScrollToTopRoute
            exact
            path="/search/:pathParam1?/:pathParam2?"
            component={Search}
          />

          {/* <ScrollToTopRoute  exact path="/loader" component={loader} /> */}
          {/* <Route exact path="filterSearch/:searchid" component={Search} /> */}
          <hr />
          <Footer />
        </Router>
      </div>
    );
  }
}
