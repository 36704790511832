import React from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import "./categories.css";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import Header from "../../components/header/header";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import E_commerc from "../../assets/icons/financial.png";
import Biology from "../../assets/icons/molecule.png";
import Chemistry from "../../assets/icons/chemistry.png";
import Science from "../../assets/icons/idea.png";
import Maths from "../../assets/icons/abacus.png";
import Economics from "../../assets/icons/financial.png";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import Topcard from "../../components/cardsection/Topcard";
import MainFooter from "../../components/footer/index";
import { Select } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import SimpleCollapse from "../../testing";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CardMedia from "@material-ui/core/CardMedia";
import Moment from "react-moment";
import moment from "moment";
import Loader from "../../components/Skeleton/loader";
import App2 from "../../testing";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { NavLink } from "react-router-dom";
export default class Categories extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: "",
      open: false,
      isChecked: true,
      isChecked2: false,
      Academic: [],
      NonAcademic: [],
      academic: "",
      Nonacademic: "",
      catagory: "",
      Catagory: [],
      loadCat: false,
      Show: true,
      text: "",
      cards: true,
      ShowError: false
    };
  }
  fatchData = () => {
    fetch("http://deaplearning.com/admin/app/api/ads/academic")
      .then(jsonResponse => jsonResponse.json())
      .then(response => {
        this.setState({ Academic: response });
      })
      .catch(error => console.log(error));
    fetch(`http://deaplearning.com/admin/app/api/ads/nonacademic`)
      .then(jsonResponse => jsonResponse.json())
      .then(response => {
        this.setState({ NonAcademic: response });
      })
      .catch(e => console.log(e, "from server"));
  };
  componentDidMount = () => {
    setTimeout(() => {
      this.fatchData();
    }, 500);
  };

  OpenModel = () => {
    this.setState({
      open: true
    });
  };
  requestData = (id, name) => {
    fetch(`http://deaplearning.com/admin/app/api/ads/filter?subject_id=${id}`)
      .then(jsonResponse => jsonResponse.json())
      .then(response => {
        if (!response.length == 0) {
          this.setState({ Catagory: response });
          this.setState({
            loadCat: true,
            Show: false,
            ShowError: false,
            cards: false,
            text: name
          });
        } else {
          this.setState({
            loadCat: false,
            ShowError: true,
            cards: false,
            Show: false,
            text: name
          });
        }
      })
      .catch(error => console.log(error));
  };

  CloseModel = () => {
    this.setState({ open: false });
  };

  toggleIsChecked = () => {
    this.setState({ isChecked: !this.state.isChecked, isChecked2: false });
  };
  toggleIsChecked2 = () => {
    this.setState({ isChecked2: !this.state.isChecked2, isChecked: false });
  };
  ButtonClick = () => {
    this.toggleIsChecked2();
  };
  handleButtonClick = () => {
    this.toggleIsChecked();
  };
  ErrorHandle = () => {
    return (
      <div className="Error">
        <h5>Oops... we didn't find anything that matches this Subject:( </h5>
        <p>Try search for something more general, change the subject</p>
        <img src={require("../../assets/icons/search-2.jpg")} />
      </div>
    );
  };
  randerAcedmic = () => {
    return (
      <div className="scrollmenu">
        {this.state.Academic.map(cat => {
          const thumb = cat.thumbnail;
          return (
            <Card className="card-cat" key={cat.id}>
              <CardActionArea
                onClick={() => this.requestData(cat.id, cat.name)}
              >
                <CardContent className="cat-div-data">
                  <img
                    src={`http://deaplearning.com/admin/app/public/img/ads/subject/${thumb}`}
                  />
                  <br />
                  <h4
                    style={{
                      color: "gray"
                    }}
                  >
                    {cat.name}
                  </h4>
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </div>
    );
  };
  RanderCatData = () => {
    let { Catagory } = this.state;
    return (
      <>
        <div className="main">
          {Catagory.map(item => {
            const thumbnails = JSON.parse(item.thumbnail);
            let formatTime = moment.utc(item.created_at).local()._d;
            if (item.featured != 0) {
              return (
                <>
                  <Card className="card" key={item.id}>
                    <NavLink
                      style={{ textDecoration: "none", color: "black" }}
                      to={`/postpre/${item.id}`}
                    >
                      <CardActionArea className="cardarea">
                        <CardMedia
                          className="media"
                          image={`http://deaplearning.com/admin/app/public/img/ads/${thumbnails[0]}`}
                        >
                          <Button className="cardbutton">Featured</Button>
                        </CardMedia>

                        <div className="moment-div">
                          <Moment fromNow>{formatTime}</Moment>
                        </div>
                        <CardContent
                          style={{
                            color: "#8A75F8",
                            borderLeft: "4px solid #8A75F8"
                          }}
                          className="add-div"
                        >
                          <Typography
                            variant="body2"
                            component="h6"
                            style={{
                              color: "black",
                              textTransform: "capitalize",
                              fontWeight: "500"
                            }}
                            className="book-headeing"
                          >
                            {item.title}
                          </Typography>

                          <Typography
                            gutterBottom
                            variant="h4"
                            component="h2"
                            className="book-price"
                            style={{ fontWeight: 800, height: 18 }}
                          >
                            {" "}
                            Rs : {item.price}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </NavLink>
                  </Card>
                </>
              );
            } else {
              return (
                <>
                  <Card className="card" key={item.id}>
                    <NavLink
                      style={{ textDecoration: "none", color: "black" }}
                      to={`/postpre/${item.id}`}
                    >
                      <CardActionArea className="cardarea">
                        <img
                          className="media"
                          src={`http://deaplearning.com/admin/app/public/img/ads/${thumbnails[0]}`}
                        />
                        <div className="moment-div">
                          <Moment fromNow>{formatTime}</Moment>
                        </div>
                        <CardContent
                          style={{ color: "#8A75F8" }}
                          className="add-div"
                        >
                          <Typography
                            variant="body2"
                            component="p"
                            style={{
                              color: "black",
                              textTransform: "capitalize",
                              fontWeight: "500"
                            }}
                            className="book-headeing"
                          >
                            {item.title}
                          </Typography>

                          <Typography
                            gutterBottom
                            variant="h4"
                            component="h2"
                            className="book-price"
                            style={{ fontWeight: 800, height: 18 }}
                          >
                            {" "}
                            Rs : {item.price}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </NavLink>
                  </Card>
                </>
              );
            }
          })}
        </div>
        <div className="loadmore">
          <Button className="morebtn"> Load More</Button>
        </div>
      </>
    );
  };

  render(props) {
    const Acedmic = this.randerAcedmic();

    let { loadCat, ShowError, cards } = this.state;
    return (
      <>
        <div maxWidth="xl">
          <main>
            <div className="mainFeaturedPost">
              <Grid container>
                <Grid item md={6}>
                  <div className="mainFeaturedPostContent">
                    <Typography
                      component="h1"
                      variant="h2"
                      color="inherit"
                      gutterBottom
                      className="Header-h1"
                    >
                      Categories
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="Catagory">
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.isChecked}
                    onChange={this.handleButtonClick}
                  />
                }
                label="Academic"
              />{" "}
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.isChecked2}
                    onChange={this.ButtonClick}
                  />
                }
                label="Non Academic"
              />{" "}
              <Collapse in={this.state.isChecked}>{Acedmic}</Collapse>
              <Collapse
                in={this.state.isChecked2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div className="scrollmenu">
                  {this.state.NonAcademic.map(cat => {
                    const thumb = cat.thumbnail;
                    return (
                      <Card className="card-cat" key={cat.id}>
                        <CardActionArea
                          onClick={() => this.requestData(cat.id, cat.name)}
                        >
                          <CardContent className="cat-div-data">
                            <img
                              src={`http://deaplearning.com/admin/app/public/img/ads/subject/${thumb}`}
                            />

                            <h4
                              style={{
                                color: "gray"
                              }}
                            >
                              {cat.name}
                            </h4>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    );
                  })}
                </div>
              </Collapse>
            </div>
            <div>
              {this.state.Show ? (
                <div className="cat-heacding">
                  <Typography
                    component="h1"
                    variant="h4"
                    style={{
                      paddingLeft: 120,
                      color: "#484ec8",
                      fontWeight: 500
                    }}
                    className="cat-btn"
                  >
                    Fresh recommendations
                  </Typography>
                </div>
              ) : (
                <div className="cat-heacding">
                  <Typography
                    component="h1"
                    variant="h4"
                    style={{
                      paddingLeft: 120,
                      color: "#484ec8",
                      fontWeight: 500
                    }}
                    className="cat-btn"
                  >
                    {this.state.text}
                  </Typography>
                </div>
              )}
              {loadCat ? this.RanderCatData() : null}
              {cards ? <Topcard /> : null}
              {this.state.ShowError ? this.ErrorHandle() : null}
            </div>
          </main>
        </div>
      </>
    );
  }
}
