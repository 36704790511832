import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import banner from "../../assets/background/Mask Group 62.png";
import "./styles.css";
import { Button, Slider } from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import Modal from "@material-ui/core/Modal";
import Slide from "@material-ui/core/Slide";
import Backdrop from "@material-ui/core/Backdrop";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import Loader from "../../components/Skeleton/loader";
export default class PostCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      Postdata: [],
      loading: true,
      postLoader: true,
      featured: []
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  loader = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <Loader />
      </div>
    );
  };

  fatchData = () => {
    fetch(`http://deaplearning.com/admin/app/api/get/ads`)
      .then(jsonResponse => jsonResponse.json())
      .then(response => {
        this.setState({ Postdata: response.data });
        this.setState({ loading: false });
      })
      .catch(error => console.log(error));
  };
  componentDidMount = () => {
    setTimeout(() => {
      this.fatchData();
    }, 500);
  };
  RanderData = () => {
    let { Postdata } = this.state;
    return (
      <>
        <div className="main">
          {Postdata.map(item => {
            const thumbnails = JSON.parse(item.thumbnail);
            let formatTime = moment.utc(item.created_at).local()._d;
            if (item.featured != 0) {
              return (
                <>
                  <Card className="card" key={item.id}>
                    <NavLink
                      style={{ textDecoration: "none", color: "black" }}
                      to={`/postpre/${item.id}`}
                    >
                      <CardActionArea
                        // href={`/postpre/${post.id}`}
                        className="cardarea"
                        
                      >
                        <CardMedia
                          className="media"
                          image={`http://deaplearning.com/admin/app/public/img/ads/${thumbnails[0]}`}
                        >
                          <Button className="cardbutton">Featured</Button>
                        </CardMedia>

                        <div className="moment-div">
                          <Moment fromNow>{formatTime}</Moment>
                        </div>
                        <CardContent
                          style={{
                            color: "#8A75F8",
                            borderLeft: "4px solid #8A75F8"
                          }}
                          className="add-div"
                        >
                          <Typography
                            variant="body2"
                            component="h6"
                            style={{
                              color: "black",
                              textTransform: "capitalize",
                              fontWeight: "500"
                            }}
                            className="book-headeing"
                          >
                            {item.title}
                          </Typography>

                          <Typography
                            gutterBottom
                            variant="h4"
                            component="h2"
                            className="book-price"
                            style={{ fontWeight: 800, height: 18 }}
                          >
                            {" "}
                            Rs : {item.price}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </NavLink>
                  </Card>
                </>
              );
            } else {
              return (
                <Card className="card" key={item.id}>
                  <NavLink
                    style={{ textDecoration: "none", color: "black" }}
                    to={`/postpre/${item.id}`}
                  >
                    <CardActionArea
                      // href={`/postpre/${item.id}`}
                     
                      className="cardarea"
                    >
                      <img
                        className="media"
                        src={`http://deaplearning.com/admin/app/public/img/ads/${thumbnails[0]}`}
                      />
                      <div className="moment-div">
                        <Moment fromNow>{formatTime}</Moment>
                      </div>
                      <CardContent
                        style={{ color: "#8A75F8" }}
                        className="add-div"
                      >
                        <Typography
                          variant="body2"
                          component="p"
                          style={{
                            color: "black",
                            textTransform: "capitalize",
                            fontWeight: "500"
                          }}
                          className="book-headeing"
                        >
                          {item.title}
                        </Typography>

                        <Typography
                          gutterBottom
                          variant="h4"
                          component="h2"
                          className="book-price"
                          style={{ fontWeight: 800, height: 18 }}
                        >
                          {" "}
                          Rs : {item.price}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </NavLink>
                </Card>
              );
            }
          })}
        </div>
      </>
    );
  };
  render() {
    let { loading, data } = this.state;
    return (
      <div>
        {loading ? this.loader() : this.RanderData()}
        <div className="loadmore">
          <Button className="morebtn"> Load More</Button>
        </div>
      </div>
    );
  }
}
