import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

const data = [];

function Media(props) {
  const { loading = false } = props;

  return (
    <div maxWidth="100%">
      {(loading ? Array.from(new Array(1)) : data).map((item, index) => (
        <div className="post-div">
          <div
            style={{
              boxShadow: "0px 1px 1px 1px rgb(231, 231, 231)",
              display: "flex",
              flexDirection: "row",
              paddingLeft: 40,
              padding: 10
            }}
          >
            <Skeleton variant="circle" width={180} height={80} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                paddingLeft: 20
              }}
            >
              <Skeleton width="60%" />
              <Skeleton width="40%" />
              <Skeleton width="20%" />
            </div>

            <div className="setting-area"></div>
          </div>
          <div class="row">
            <div class="col-lg-6" style={{ marginTop: -150 }}>
              <div class="img-div">
                <Skeleton width="100%" height={700} />
              </div>
            </div>
            <div class="col-lg-6 col-xl-5">
              <div className="content">
                <br /> <br />
                <Skeleton width="100%" />
                <Skeleton width="50%" />
                <br className="br" />
                <hr style={{ width: "90%" }} />
                <br className="br" />
                <Skeleton width="100%" />
                <Skeleton width="50%" />
                <br className="br" />
                <hr style={{ width: "90%" }} />
                <br className="br" />
                <Skeleton width="100%" />
                <Skeleton width="50%" />
                <br className="br" />
                <hr style={{ width: "90%" }} />
                <br className="br" />
                <Skeleton width="100%" />
                <Skeleton width="50%" />
                <br className="br" />
                <hr style={{ width: "90%" }} />
                <Skeleton width="100%" />
                <Skeleton width="50%" />
                <br className="br" />
                <hr style={{ width: "90%" }} />
                <Skeleton width="100%" />
                <Skeleton width="50%" />
                <br className="br" />
                <br />
                <br />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Skeleton variant="circle" width={60} height={60} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      paddingLeft: 20
                    }}
                  >
                    <Skeleton width="60%" />
                    <Skeleton width="40%" />
                    <Skeleton width="20%" />
                  </div>
                </div>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

Media.propTypes = {
  loading: PropTypes.bool
};

export default function PostLoader() {
  return (
    <Box overflow="hidden">
      <Media loading />
    </Box>
  );
}
