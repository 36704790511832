import React from "react";
import "./demo.css";
import Button from "@material-ui/core/Button";

export default function Section() {
  return (
    <div>
      <section class="download_area" id="download">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 wow fadeInLeft animated" data-wow-delay="10s">
              <div className="downlaod_img">
                <img src={require("../../assets/background/mobile.png")} />
              </div>
            </div>
            <div class="col-lg-6 col-xl-6 ml-xl-auto download_contant">
              <div class="special_download_content">
                <h2>Try The TiktakBook App</h2>
                <br />
                <p style={{ fontSize: 20, color: "white" }}>
                  {
                    " Buy, Sell ,Exchange and donate for good costs just about anything using the app on your mobile "
                  }
                </p>
                <div class="app-download-area">
                  <div
                    class="app-download-btn wow fadeInUp animated"
                    data-wow-delay="0.2s"
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.tiktakbook"
                      target="blank"
                    >
                      <img
                        src={require("../../assets/icons/playstore.png")}
                        style={{ width: 40 }}
                        className="play"
                      />
                      <p class="mb-0">
                        <span>available on</span> Google Store
                      </p>
                    </a>
                  </div>
                  <div
                    class="app-download-btn wow fadeInDown animated"
                    data-wow-delay="0.4s"
                  >
                    <a href="#">
                      <img
                        src={require("../../assets/icons/apple.png")}
                        style={{ width: 18 }}
                      />
                      <p class="mb-0">
                        <span> available on</span> Apple Store
                      </p>
                    </a>
                  </div>
                </div>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="subscribe">
              <div class="section-heading1 text-center">
                <h2>Newsletter</h2>
                <br />
                <input type="email" class="inputsub" placeholder="Your Email" />
              </div>
            </div>
          </div>

          <div className="loadmore">
            <Button className="morebtn">
              {" "}
              submit
            </Button>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
